import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Box, Button } from "@mui/material";
import Spinner from "react-spinner-material";
import REACT_APP from "../../../environment";
import axios from "axios";
import InsuranceEnroll from "./Enroll";
import "./style.css";
import InsuranceUnenroll from "./Unenroll";

const InsuranceEnrollmentDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isWaiting, setIsWaiting] = useState(false);
    const [openEnroll, setOpenEnroll] = useState(false);
    const [openUnenroll, setOpenUnenroll] = useState(false);
    const [idx, setIdx] = useState(null);
    const params = useParams();

    const getData = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/insurance/enroll/provider/${params.id}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                setData(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const onBack = () => navigate("/benefits/insurance/enrollment");

    return (
        <div className="container my-2">
            <Button size="small" style={{ float: "right" }} variant="outlined" color="error" onClick={onBack}>
                {t("Go back")}
            </Button>
            {isWaiting && <div className="overlay"></div>}
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%", marginTop: "20px" }}>
                    {!error && !data && (
                        <Alert variant="outlined" severity="warning">
                            {t("No data yet")}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            {t("An error occurred")}
                        </Alert>
                    )}
                    {data && (
                        <div>
                            <h3 style={{ marginLeft: "16px" }}>{data.name}</h3>
                            {data.products.length === 0 && (
                                <Alert variant="outlined" severity="warning">
                                    {t("Products are not available")}
                                </Alert>
                            )}
                            {data.products.map((product) => (
                                <div>
                                    {/* <hr /> */}
                                    <h4>{product.name}</h4>
                                    <p style={{ textAlign: "justify" }}>{product.description}</p>
                                    {product.types.map((types) => (
                                        <div className="px-3 my-2 text-justify" style={{ borderLeft: "2px #eee ridge" }}>
                                            <h5>
                                                {types.name}
                                                {types.status && <span className="i-enrolled">{t("enrolled")}</span>}
                                            </h5>
                                            <p style={{ textAlign: "justify" }}>{types.description}</p>
                                            <div>
                                                <span>{t("deductable amount")}: </span>
                                                <span>{types.deductable_amount}</span>
                                            </div>
                                            <div>
                                                <span>{t("insurance amount")}: </span>
                                                <span>{types.insurance_amount}</span>
                                            </div>
                                            {types.status ? (
                                                <InsuranceUnenroll
                                                    open={openUnenroll}
                                                    setOpen={setOpenUnenroll}
                                                    getData={getData}
                                                    setIsWaiting={setIsWaiting}
                                                    product_type_id={types.product_type_id}
                                                    id={idx}
                                                    setIdx={setIdx}
                                                />
                                            ) : (
                                                <InsuranceEnroll
                                                    open={openEnroll}
                                                    setOpen={setOpenEnroll}
                                                    getData={getData}
                                                    setIsWaiting={setIsWaiting}
                                                    product_type_id={types.product_type_id}
                                                    id={idx}
                                                    setIdx={setIdx}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </Box>
            )}
        </div>
    );
};

export default InsuranceEnrollmentDetails;
