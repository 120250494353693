import Tabs from "./Tabs";

const Reviews = () => {
    return (
        <div className="container">
            <Tabs />
        </div>
    );
};

export default Reviews;
