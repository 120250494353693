import { useEffect, useState } from "react";
import Employees from "./Employees";
import Managers from "./Managers";
import REACT_APP from "../../../../environment";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Department = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const getData = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/organization/department`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => setData(res.data.data))
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <div className="container">
                <h2 style={{ textTransform: "capitalize" }}>{data.name || t("My Department")}</h2>
            </div>
            <Managers />
            <Employees />
        </div>
    );
};

export default Department;
