import Joi from "joi";
const validation = Joi.object({
    to_employee_id: Joi.array()
        .items(Joi.number().integer())
        .min(1)
        .required()
        .error((errors) => {
            if (errors[0].code === "array.min") errors[0].message = "Select employees";
            else errors[0].message = "Something went wrong";
            return errors;
        }),
    comment: Joi.string()
        .required()
        .error((errors) => {
            errors[0].message = "Comment is required";
            return errors;
        }),
    core_value_id: Joi.number()
        .integer()
        .error((errors) => {
            if (errors[0].code === "number.base") errors[0].message = "Select badge";
            else errors[0].message = "Something went wrong";
            return errors;
        }),
    notify_manager: Joi.boolean()
        .required()
        .error((errors) => {
            errors[0].message = "Something went wrong";
            return errors;
        }),
    is_public: Joi.boolean()
        .required()
        .error((errors) => {
            errors[0].message = "Something went wrong";
            return errors;
        }),
});

export default validation;
