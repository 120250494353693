import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useState } from "react";
import ModalCalendar from "./ModalCalendar";
import REACT_APP from "../../environment";

//dnd calendar
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

// react BigCalendar component
const BigCalendar = () => {
    const [events, setEvents] = useState([]);

    //states for creating event
    const [modalStatus, setModalStatus] = useState(false);
    const [eventInput, setEventInput] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    //state for on select event
    const [eventId, setEventId] = useState("");
    const [editStatus, setEditStatus] = useState(false);

    const handleClose = () => {
        setModalStatus(false);
        setEditStatus(false);
        setEventInput("");
    };

    const handleChange = (e) => {
        setEventInput(e.target.value);
    };

    const handleSave = async () => {
        if (eventInput) {
            const body = {
                title: `${eventInput}`,
                start: new Date(`${startDate}`),
                end: new Date(`${endDate}`),
            };

            await axios
                .post(`${REACT_APP.API}/api/hrp/v1/employee/calendar`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    setModalStatus(false);
                    getData();
                })
                .catch((err) => toast.error("An error occurred"));
        }
    };

    //slot select handler
    const handleSlotSelectEvent = (slotInfo) => {
        setStartDate(new Date(`${slotInfo.start}`));
        setEndDate(new Date(`${slotInfo.end}`));
        setModalStatus(true);
        setEventInput("");
    };

    //move event handler
    const moveEventHandler = ({ event, start, end }) => {
        if (event.editable === true) {
            const body = { id: `${event.id}`, title: `${event.title.props.children[0]}`, start: new Date(`${start}`), end: new Date(`${end}`) };

            axios
                .put(`${REACT_APP.API}/api/hrp/v1/employee/calendar`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    setModalStatus(false);
                    getData();
                })
                .catch((err) => toast.error("An error occurred"));
        }
    };

    //resize event handler
    const resizeEventHandler = ({ event, start, end }) => {
        if (event.editable === true) {
            const body = { id: `${event.id}`, title: `${event.title.props.children[0]}`, start: new Date(`${start}`), end: new Date(`${end}`) };

            axios
                .put(`${REACT_APP.API}/api/hrp/v1/employee/calendar`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    setModalStatus(false);
                    getData();
                })
                .catch((err) => toast.error("An error occurred"));
        }
    };

    //on select event handler
    const hanldeOnSelectEvent = (e) => {
        if (e.editable === true) {
            setEditStatus(true);
            setStartDate(new Date(`${e.start}`));
            setEndDate(new Date(`${e.end}`));
            setEventInput(e.title.props.children[0]);
            setEventId(e.id);
            setModalStatus(true);
        }
    };

    const handleEditEvent = (e) => {
        setEventInput(e.target.value);
    };

    const handleEdited = async (e) => {
        if (eventInput) {
            const body = {
                id: `${eventId}`,
                title: `${eventInput}`,
                start: new Date(`${startDate}`),
                end: new Date(`${endDate}`),
            };

            await axios
                .put(`${REACT_APP.API}/api/hrp/v1/employee/calendar`, body, { headers: { Authorization: sessionStorage.getItem("token") } })
                .then((res) => {
                    setModalStatus(false);
                    getData();
                })
                .catch((err) => toast.error("An error occurred"));
        }

        setEditStatus(false);
        setEventInput("");
    };

    // on delete event handler
    const handleDelete = (e) => {
        axios
            .delete(`${REACT_APP.API}/api/hrp/v1/employee/calendar`, {
                data: { id: `${eventId}` },
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setModalStatus(false);
                setEventInput("");
                getData();
            })
            .catch((err) => toast.error("An error occurred"));
    };

    // get all data
    const getData = async () => {
        await axios(`${REACT_APP.API}/api/hrp/v1/employee/calendar`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                res.data.data?.forEach((el) => {
                    el.start = new Date(el.start);
                    el.end = new Date(el.end);
                    el.tooltip = el.title;
                    el.title = (
                        <p id="c_title">
                            {el.title} <span id="f_name">{el.first_name?.slice(0, 1) + "." + el.last_name}</span>
                        </p>
                    );
                });
                setEvents(res.data.data);
            })
            .catch((err) => toast.error("An error occurred getting events"));
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="my-calendar">
            <DnDCalendar
                localizer={localizer}
                events={events}
                tooltipAccessor={"tooltip"}
                startAccessor="start"
                endAccessor="end"
                selectable
                //event trigger after clicking any slot
                onSelectSlot={handleSlotSelectEvent}
                //event trigger after clicking any event
                onSelectEvent={hanldeOnSelectEvent}
                //event for drag and drop
                onEventDrop={moveEventHandler}
                //event trigger hen resizing any event
                resizable
                onEventResize={resizeEventHandler}
                // onSelecting={slot => false}
                longPressThreshold={10}
                eventPropGetter={() => ({
                    style: {
                        backgroundColor: "rgba(80, 130, 180, 0.3)",
                        color: "black",
                        borderLeft: "3px solid rgb(80, 130, 180)",
                        borderRadius: "0",
                        fontFamily: "monospace",
                    },
                })}
            />
            <ModalCalendar
                modalStatus={modalStatus}
                handleClose={handleClose}
                handleSave={handleSave}
                handleChange={handleChange}
                startDate={startDate}
                endDate={endDate}
                eventInput={eventInput}
                handleEditEvent={handleEditEvent}
                handleEdited={handleEdited}
                editStatus={editStatus}
                handleDelete={handleDelete}
            />
        </div>
    );
};

export default BigCalendar;
