/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import validation from "./validators/update";
import { Textarea } from "@mui/joy";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

const EditTimeOff = ({ handleClose, row, setOverlay, getAllVacations }) => {
    const { t } = useTranslation();
    const [types, settypes] = useState([]);
    const [desc, setdesc] = useState("");
    const [newDesc, setNewDesc] = useState("");
    const [days, setdays] = useState(row.items);

    const getAllVacationTypes = () => {
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/vacation/requests/types`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                settypes(res.data.data);
                for (let i = 0; i < res.data.data.length; i++) if (res.data.data[i].name === row.vacation_request_type) setAge(res.data.data[i].id);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
            });
    };
    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const descChange = (event) => {
        // setdesc(event.target.value);
        setNewDesc(event.target.value);
    };

    const dateChangeHandler = () => {
        let end_date = document.getElementById("end_date")?.value;
        const start_date = document.getElementById("start_date")?.value;
        let arr = [];
        if (start_date > end_date) {
            document.getElementById("end_date").value = start_date;
            end_date = start_date;
        }
        for (let i = 0, start = new Date(start_date).getTime(), end = new Date(end_date).getTime(); i <= (end - start) / 86400000; i++) {
            arr.push({
                hour: new Date(start + i * 86400000).getDay() % 6 === 0 ? 0 : 8,
                date: new Date(start + i * 86400000).toISOString().slice(0, 10),
            });
        }
        setdays(arr);
    };
    const hourChangeHandler = (event, date) => {
        days[days.findIndex((x) => x?.date === date)].hour = event.target.value;
        setdays(days);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const value = {
            vacation_request_type_id: event.target.type.value,
            description: newDesc,
            items: days,
        };
        if (validation.validate(value).error) {
            toast.error(validation.validate(value).error.message);
        } else {
            setOverlay(true);
            console.log(value);
            axios
                .put(`${REACT_APP.API}/api/hrp/v1/vacation/${row.id}`, value, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then(() => {
                    handleClose();
                    setOverlay(false);
                    getAllVacations();
                })
                .catch((err) => {
                    setOverlay(false);
                    toast.error(t("Something went wrong"));
                });
        }
    };

    React.useEffect(() => {
        getAllVacationTypes();
        row.items.forEach((item) => {
            delete item.vacation_request_id;
            delete item.id;
            item.date = item.date.slice(0, 10);
        });
        setdays(row.items);
        setdesc(row.description);
    }, []);

    return (
        <React.Fragment>
            <Paper elevation={3} sx={{ marginRight: "3%", marginLeft: "3%" }}>
                <form
                    onSubmit={handleSubmit}
                    style={{
                        maxHeight: "90vh",
                        overflowX: "hidden",
                    }}
                >
                    <Box sx={{ padding: 5 }}>
                        <Typography variant="h6" gutterBottom sx={{ paddingBottom: 5, paddingLeft: 5 }}>
                            {t("Time Off Apply")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("Type")}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="type"
                                        value={age}
                                        label={t("Age")}
                                        onChange={handleChange}
                                    >
                                        {types.map((item, i) => (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("Start date")}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    InputProps={{
                                        inputProps: { min: new Date().toISOString().slice(0, 10) },
                                    }}
                                    required
                                    type="date"
                                    name="start_date"
                                    id="start_date"
                                    defaultValue={row.start_date?.slice(0, 10)}
                                    label={t("Start date")}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    onChange={dateChangeHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("End date")}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    InputProps={{
                                        inputProps: {
                                            min: document.getElementById("start_date")?.value,
                                        },
                                    }}
                                    required
                                    type="date"
                                    defaultValue={row.end_date?.slice(0, 10)}
                                    name="end_date"
                                    id="end_date"
                                    label={t("End date")}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    onChange={dateChangeHandler}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("Hours")}:
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9}></Grid>
                            {days.map((item, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} sm={3}>
                                        <InputLabel
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                fontWeight: 700,
                                            }}
                                        >
                                            {item.date}
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <TextField
                                            InputProps={{
                                                inputProps: { min: 0, max: 16 },
                                            }}
                                            required
                                            type="number"
                                            name="hour"
                                            label={t("Hour")}
                                            fullWidth
                                            size="small"
                                            autoComplete="off"
                                            variant="outlined"
                                            defaultValue={item.hour}
                                            onChange={(e, date = item.date) => {
                                                hourChangeHandler(e, date);
                                            }}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ))}
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontWeight: 700,
                                    }}
                                >
                                    {t("Description")}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Textarea minRows={2} maxRows={3} onChange={(e) => descChange(e)} defaultValue={desc} />
                            </Grid>
                            <Grid item xs={12} sm={8} />
                            <Grid item xs={12} sm={2}>
                                <Button variant="contained" type="submit">
                                    {t("Save")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button variant="contained" color="error" onClick={handleClose}>
                                    {t("Cancel")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Paper>
        </React.Fragment>
    );
};

export default EditTimeOff;
