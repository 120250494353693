import React from "react";
import { useTranslation } from "react-i18next";

// const DASHBOARDS = [
//   {
//     id: "1",
//     tax: "some tax",
//     filingStatus: "some status",
//     dependents: "family",
//     otherIncome: "second work",
//     deductions: "some deductions",
//     type: "some type",
//     amount: "some amount",
//     percentage: "2%",
//   },
//   {
//     id: "2",
//     tax: "some tax",
//     filingStatus: "some status",
//     dependents: "family",
//     otherIncome: "second work",
//     deductions: "some deductions",
//     type: "some type",
//     amount: "some amount",
//     percentage: "2%",
//   },
//   {
//     id: "3",
//     tax: "some tax",
//     filingStatus: "some status",
//     dependents: "family",
//     otherIncome: "second work",
//     deductions: "some deductions",
//     type: "some type",
//     amount: "some amount",
//     percentage: "2%",
//   },
// ];

const Dashboards = () => {
    const { t } = useTranslation();
    return (
        <div className="container mt-3 cntnr2">
            <h2 className="h2">{t("Dashboard")}</h2>
            <div className="table-responsive">
                {/* <table data-testid="table" className="table table-bordered">
          <thead data-testid="thead">
            <tr>
              <th>Tax</th>
              <th>Filing Status</th>
              <th>Dependents</th>
              <th>Other income</th>
              <th>Deductions</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody data-testid="tbody">
            {DASHBOARDS.map((DASHBOARD) => (
              <tr key={DASHBOARD.id}>
                <td>{DASHBOARD.tax}</td>
                <td>{DASHBOARD.filingStatus}</td>
                <td>{DASHBOARD.dependents}</td>
                <td>{DASHBOARD.otherIncome}</td>
                <td>{DASHBOARD.deductions}</td>
                <td>{DASHBOARD.type}</td>
                <td>{DASHBOARD.amount}</td>
                <td>{DASHBOARD.percentage}</td>
              </tr>
            ))}
          </tbody>
        </table> */}
            </div>
        </div>
    );
};

export default Dashboards;
