/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import "./recognition.css";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Spinner from "react-spinner-material";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

function Results(props) {
    const { t } = useTranslation();
    const [recogs, setRecogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let query = `?department=${props.member}`;
        if (props.core !== 0) query += `&core_id=${props.core}`;
        query += `&date=${getDate(props.date)}`;
        getRecognitions(query);
    }, [props]);

    useEffect(() => {
        // rasmlarni get qilish uchun
        if (recogs.length > 0 && props.coreValues.length > 0) {
            // so'rovni kamaytirish uchun
            let coreValues = [];
            recogs.forEach((e) => {
                let i = coreValues.findIndex((x) => x.id === e.core_value_id);
                if (i === -1) coreValues.push(props.coreValues[props.coreValues.findIndex((x) => x.id === e.core_value_id)]);
            });
            // so'rovlar
            for (let i = 0; i < coreValues.length; i++) {
                fetch(`${REACT_APP.API}/api/hrp/v1/recognition/core/image/${coreValues[i].image}`, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                    .then((res) => res.blob())
                    .then((blob) => {
                        // rasmni element source ga berish
                        if (/image/.test(blob.type)) {
                            let x = document.getElementsByClassName("cv" + coreValues[i].id);
                            for (let j = 0; j < x.length; j++) {
                                x[j].src = window.URL.createObjectURL(blob);
                            }
                        }
                    });
            }
        }
    }, [recogs]);

    const getRecognitions = (query) => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/recognize${query}&limit=100`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                res.data.data.forEach((e) => {
                    if (props.coreValues.length > 0) e.core_value = props.coreValues[props.coreValues.findIndex((x) => x?.id === e.core_value_id)].name;
                });
                setRecogs(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    return (
        <div className="r-badges">
            <div className="rb-items" style={recogs.length > 5 ? { justifyContent: "center" } : { margin: "auto 2em" }}>
                {loading && (
                    <div className="spinner21">
                        <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                    </div>
                )}
                {!loading &&
                    recogs.map((item, index) => (
                        <div className="rbi-item" key={index}>
                            <div className="rbii-head">
                                <img className={"cv" + item.core_value_id} alt={item.core_value} />
                                <h6 className="rbiih-name">{toTitleCase(item.to_employee_first_name + " " + item.to_employee_last_name)}</h6>
                            </div>
                            <h6>{item.core_value}</h6>
                            <h6 style={{ color: "#555" }}>{item.created_date?.slice(0, 10)}</h6>
                            <div className="rbii-body">
                                <div className="rbiib-comment">
                                    <span className="rbiibc-from">{toTitleCase(item.from_employee_first_name + " " + item.from_employee_last_name)}: </span>
                                    {item.comment && typeof item.comment === "string" && item.comment.length > 30 ? (
                                        <Tooltip title={<h6 style={{ color: "white" }}>{item.comment}</h6>}>
                                            <span style={{ cursor: "zoom-in" }}>{item.comment}</span>
                                        </Tooltip>
                                    ) : (
                                        <span>{item.comment}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                {!loading && recogs.length === 0 && <h6 style={{ marginLeft: "3rem" }}>{t("Not found")}</h6>}
            </div>
        </div>
    );
}

export default React.memo(Results);

function getDate(value) {
    switch (value) {
        case "this week":
            return new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                parseInt(new Date().getDate()) - (parseInt(new Date().getDay() + (7 - 1)) % 7)
            ).toISOString();
        case "this month":
            return new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString();
        case "this quarter":
            return new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3, 1).toISOString();
        case "this year":
            return new Date(new Date().getFullYear(), 0, 1).toISOString();
        case "last year":
            return new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDay()).toISOString();
        case "all time":
            return new Date(0).toISOString();
        default:
            return new Date(0).toISOString();
    }
}

function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
