/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Fade, Grid, Modal, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { array, object, string } from "yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const WorkHistory = ({ handleClosee, id, getDataa }) => {
    const { t } = useTranslation();
    const [view, setView] = useState(false);
    const [works, setWorks] = useState();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        getWork();
    }, []);

    const emptyWorkHistory = {
        name: "",
        position: "",
        start_date: "",
        end_date: "",
        phone: "",
        email: undefined,
        website: undefined,
        supervisor: { name: "", position: "", phone: "", email: "" },
        references: [
            { first_name: "", last_name: "", position: "", phone: "", email: "" },
            { first_name: "", last_name: "", position: "", phone: "", email: "" },
        ],
        address: {
            country: "",
            address1: "",
            address2: undefined,
            city: "",
            region: "",
            zip_code: undefined,
        },
    };

    const workHistorySchema = object({
        name: string()
            .matches(regex.company_name, { message: t("Invalid name") })
            .required(t("Name is required")),
        position: string()
            .matches(regex.work_position, { message: t("Invalid position") })
            .required(t("Position is required")),
        start_date: Yup.date().max(new Date(), t("Future date not allowed")).typeError(t("Invalid start date")).required(t("Start date is required")),
        end_date: Yup.date()
            .required(t("End date is required"))
            .when("start_date", (start_date, yup) => start_date && yup.min(start_date, t("End date cannot be before start date"))),
        email: string().email(t("Invalid email")).nullable(),
        phone: string()
            .matches(regex.phone, { message: t("Invalid phone number") })
            .required(t("Your phone is required")),
        website: string()
            .matches(regex.website, { message: t("Invalid website") })
            .nullable(),

        supervisor: object({
            name: string()
                .matches(regex.full_name, { message: t("Invalid name") })
                .required(t("Name is required")),
            position: string()
                .matches(regex.work_position, { message: t("Invalid position") })
                .required(t("Position is required")),
            phone: string()
                .matches(regex.phone, { message: t("Invalid phone number") })
                .required(t("Supervisor phone is required")),
            email: string().required(t("Supervisor email is required")).email(t("Invalid email")),
        }),

        references: array(
            object({
                first_name: string()
                    .matches(regex.first_name, { message: t("Invalid first name") })
                    .required(t("First name is required")),
                last_name: string()
                    .matches(regex.last_name, { message: t("Invalid last name") })
                    .required(t("Last name is required")),
                position: string()
                    .matches(regex.work_position, { message: t("Invalid position") })
                    .required(t("Position is required")),
                phone: string()
                    .matches(regex.phone, { message: t("Invalid phone number") })
                    .required(t("Supervisor phone is required")),
                email: string().required(t("Supervisor email is required")).email(t("Invalid email")),
            })
        ),

        address: object({
            country: string().required(t("Country is required")),
            address1: string()
                .matches(regex.address1, { message: t("Invalid address 1") })
                .required(t("Address 1 is required")),
            address2: string()
                .matches(regex.address2, { message: t("Invalid address 2") })
                .nullable(),
            city: string()
                .matches(regex.city, { message: t("Invalid city") })
                .required(t("City is required")),
            region: string().required(t("Region is required")),
            zip_code: string()
                .matches(regex.zip_code, { message: t("Invalid ZipCode") })
                .nullable()
                .optional(),
        }),
    });

    const getWork = async () => {
        await fetch(`${REACT_APP.API}/api/hrp/v1/employee/workhistory/${id}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((response) => response.json())
            .then((res) => {
                if (res?.data?.address?.zip_code === null) {
                    res.data.address.zip_code = "";
                }
                setWorks(res.data);
                setTimeout(() => {
                    setView(true);
                }, 1000);
            })
            .catch((err) => {
                setView(false);
                toast.error(t("An error occurred while getting Works!"));
            });
    };

    const submit = (value) => {
        if (!value?.address?.zip_code.length) {
            value.address.zip_code = null;
        }
        setLoading(true);
        axios
            .put(`${REACT_APP.API}/api/hrp/v1/employee/edit/work`, value, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                handleClosee();
                getDataa();
                setLoading(false);
                toast.success(t("Saved successfully"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("An error occurred while saving work histories!"));
            });
    };

    return (
        <>
            {loading && <div className="hrp-overlay"></div>}
            <div>
                <Button onClick={handleOpen}>{t("Review")}</Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box className="res_modal overflow_scroll" sx={style}>
                            <Button onClick={handleClose} variant="contained" color="primary" style={{ float: "right" }}>
                                <CloseIcon />
                            </Button>
                            <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                                {t("Work history details")}
                            </Typography>
                            {view ? (
                                <Formik
                                    initialValues={works?.length !== 0 ? works : { work: emptyWorkHistory }}
                                    validationSchema={workHistorySchema}
                                    onSubmit={async (values) => {
                                        submit(values);
                                        return new Promise((res) => setTimeout(res, 500));
                                    }}
                                >
                                    {({ values, errors, isSubmitting }) => (
                                        <Form autoComplete="off">
                                            <Grid container direction="column" spacing={2}>
                                                <React.Fragment>
                                                    <Grid item container spacing={2} xs={12}>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Name")}
                                                                name={`name`}
                                                                component={TextField}
                                                                autoComplete="name"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Position")}
                                                                name={`position`}
                                                                component={TextField}
                                                                autoComplete="position"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Phone")}
                                                                name={`phone`}
                                                                component={TextField}
                                                                autoComplete="phone"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Website")}
                                                                name={`website`}
                                                                component={TextField}
                                                                autoComplete="website"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Email")}
                                                                name={`email`}
                                                                component={TextField}
                                                                autoComplete="email"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Start Date")}
                                                                name={`start_date`}
                                                                component={TextField}
                                                                autoComplete="start_date"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("End date")}
                                                                name={`end_date`}
                                                                component={TextField}
                                                                autoComplete="end_date"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <p className="text-center mt-3">{t("Supervisor")}</p>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Name")}
                                                                name={`supervisor.name`}
                                                                component={TextField}
                                                                autoComplete="name"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Position")}
                                                                name={`supervisor.position`}
                                                                component={TextField}
                                                                autoComplete="position"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Phone")}
                                                                name={`supervisor.phone`}
                                                                component={TextField}
                                                                autoComplete="phone"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Email")}
                                                                name={`supervisor.email`}
                                                                component={TextField}
                                                                autoComplete="email"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <p className="text-center mt-3">{t("References")}</p>
                                                        </Grid>

                                                        {values.reference?.map((_, idx) => (
                                                            <Grid container item key={idx} spacing={2}>
                                                                <Grid item container spacing={2} xs={12}>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            label={t("First name")}
                                                                            name={`reference[${idx}].first_name`}
                                                                            component={TextField}
                                                                            autoComplete="first name"
                                                                            type="text"
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            label={t("Last name")}
                                                                            name={`reference[${idx}].last_name`}
                                                                            component={TextField}
                                                                            autoComplete="last name"
                                                                            type="text"
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Field
                                                                            fullWidth
                                                                            label={t("Position")}
                                                                            name={`reference[${idx}].position`}
                                                                            component={TextField}
                                                                            autoComplete="position"
                                                                            type="text"
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Field
                                                                            fullWidth
                                                                            label={t("Phone")}
                                                                            name={`reference[${idx}].phone`}
                                                                            component={TextField}
                                                                            autoComplete="phone"
                                                                            type="text"
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Field
                                                                            fullWidth
                                                                            label={t("Email")}
                                                                            name={`reference[${idx}].email`}
                                                                            component={TextField}
                                                                            autoComplete="Email"
                                                                            type="text"
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}

                                                        <Grid item xs={12}>
                                                            <p className="text-center mt-3">{t("Address")}</p>
                                                        </Grid>

                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Address1")}
                                                                name={`address.address1`}
                                                                component={TextField}
                                                                autoComplete="address1"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Address2")}
                                                                name={`address.address2`}
                                                                component={TextField}
                                                                autoComplete="address2"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Address1")}
                                                                name={`address.city`}
                                                                component={TextField}
                                                                autoComplete="address1"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Zip Code")}
                                                                name={`address.zip_code`}
                                                                component={TextField}
                                                                autoComplete="zip_code"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Country")}
                                                                name={`address.country`}
                                                                component={TextField}
                                                                autoComplete="country"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Field
                                                                fullWidth
                                                                label={t("Region")}
                                                                name={`address.region`}
                                                                component={TextField}
                                                                autoComplete="region"
                                                                type="text"
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        {typeof errors.work === "string" ? <Typography color="error">{errors.work}</Typography> : null}
                                                    </Grid>
                                                </React.Fragment>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            ) : (
                                <div>
                                    <Spin tip="Loading" size="large">
                                        <div className="content" />
                                    </Spin>
                                </div>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    );
};
export default WorkHistory;
