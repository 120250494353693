/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CoreData from "./CoreData";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Spinner from "react-spinner-material";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function BasicTabs() {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const handleChange = (event, newValue) => setValue(newValue);

    const getData = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/recognition/core`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                setData(res.data.data);
            })
            .catch((err) => setLoading(false));
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="tabs_box">
            {loading ? (
                <div style={{ marginTop: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "80%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" orientation={isSmallScreen ? "vertical" : "horizontal"}>
                            {data.length && data.map((item, index) => <Tab key={index} label={item.name} {...a11yProps(index)} />)}
                        </Tabs>
                    </Box>
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            <TabPanel key={index} value={value} index={index}>
                                <CoreData description={item.description} name={item.name} image={item.image} id={item.id} />
                            </TabPanel>
                        ))
                    ) : (
                        <h5 className="mt-4">Core values not found</h5>
                    )}
                </Box>
            )}
        </div>
    );
}

export default React.memo(BasicTabs);
