import AddressTable from "./AddressTable";

const Address = () => {
    return (
        <div className="container">
            <AddressTable />
        </div>
    );
};

export default Address;
