/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../environment";

const Modal = ({ id }) => {
    const { t } = useTranslation();
    const qy = document.querySelector.bind(document);
    const [loading, setLoading] = useState(true);

    const getFile = async () => {
        setLoading(true);
        await axios(`${REACT_APP.API}/api/hrp/v1/payroll/${id}/report`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            responseType: "blob",
        })
            .then((blob) => {
                setLoading(false);
                setTimeout(() => {
                    qy("#pay").src = window.URL.createObjectURL(blob.data);
                });
            })
            .catch((err) => setLoading(false));
    };

    useEffect(() => {
        getFile();
    }, [id]);

    return (
        <>
            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#payModal">
                {t("View Pay Stub")}
            </button>
            <div className="modal fade" id="payModal" tabIndex="-1" aria-labelledby="payModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content bg-white">
                        <div className="modal-header py-1">
                            <h5 className="modal-title" id="payModalLabel">
                                File
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body file_md img-fluid">
                            {loading ? (
                                <div className="spinner-border text-info my-5" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                // eslint-disable-next-line jsx-a11y/iframe-has-title
                                <iframe id="pay" src="" alt="" height="750" width="900" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
