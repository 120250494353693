import CerTable from "./CerTable";

const Certification = () => {
    return (
        <div className="container">
            <CerTable />
        </div>
    );
};

export default Certification;
