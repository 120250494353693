import React from "react";
import day from "dayjs";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import Spinner from "react-spinner-material";
import Alert from "@mui/material/Alert";
// import AddEduModal from "./AddEduModal";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../environment";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function InsuranceTableHead(props) {
    const { t } = useTranslation();
    const headCells = [
        { id: "id", numeric: true, label: "Id" },
        { id: "start_date", numeric: true, label: t("Start date") },
        { id: "end_date", numeric: true, label: t("End date") },
        { id: "major", numeric: true, label: t("Major") },
        { id: "degree_type", numeric: true, label: t("Degree type") },
    ];

    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

InsuranceTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function InsuranceTable() {
    const { t } = useTranslation();
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const getData = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/insurance/enroll`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                setData(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
            });
    };

    React.useEffect(() => {
        getData();
    }, []);

    const navigate = useNavigate();
    const newInsurance = () => navigate("/benefits/insurance/enrollment");

    return (
        <div className="container my-2">
            <Button size="small" style={{ float: "right" }} variant="outlined" color="primary" onClick={newInsurance}>
                {t("New enroll")}
            </Button>
            <h3 style={{ marginLeft: "16px" }}>{t("Insurances")}</h3>
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%", marginTop: "20px" }}>
                    {!error && data.length === 0 && (
                        <Alert variant="outlined" severity="warning">
                            {t("No Data yet")}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            {t("An error occurred")}
                        </Alert>
                    )}
                    {data.map((e) => (
                        <Paper sx={{ width: "100%", mb: 2, p: 2 }}>
                            <h5>
                                {e.product_name} - {e.product_type_name}
                            </h5>

                            <div>
                                <span>{t("status")}: </span>
                                <span>{e.status_name}</span>
                            </div>
                            <div>
                                <span>{t("insurance amount")}: </span>
                                <span>{e.insurance_amount}</span>
                            </div>
                            <div>
                                <span>{t("deductable amount")}: </span>
                                <span>{e.deductable_amount}</span>
                            </div>
                            {e.enrollment_date && (
                                <div>
                                    <span>{t("enrollment date")}: </span>
                                    <span>{e.enrollment_date.slice(0, 10)}</span>
                                </div>
                            )}
                            {e.expirition_date && (
                                <div>
                                    <span>{t("expirition date")}: </span>
                                    <span>{e.expirition_date.slice(0, 10)}</span>
                                </div>
                            )}
                            <div style={{ textAlign: "justify" }}>
                                <b>{e.product_name}. </b>
                                {e.product_description}
                            </div>
                            <div style={{ textAlign: "justify" }}>
                                <b>{e.product_type_name}. </b>
                                {e.product_type_description}
                            </div>
                            <h6 style={{ marginTop: "20px", marginBottom: "3px" }}>{t("Insurance company")}</h6>
                            <div>
                                <span>{t("p-name")}: </span>
                                <span>{e.provider_name}</span>
                            </div>
                            <div>
                                <span>{t("email")}: </span>
                                <span>{e.provider_email}</span>
                            </div>
                            {e.provider_website && (
                                <div>
                                    <span>{t("website")}: </span>
                                    <span>{e.provider_website}</span>
                                </div>
                            )}
                        </Paper>
                    ))}
                </Box>
            )}
        </div>
    );
}

export default React.memo(InsuranceTable);
