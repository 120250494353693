import { BrowserRouter } from "react-router-dom";
import { MessageProvider } from "./CheckContext";
import { I18nextProvider } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom/client";
import i18n from "./i18n";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <I18nextProvider i18n={i18n}>
            <MessageProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </MessageProvider>
        </I18nextProvider>
    </>
);
