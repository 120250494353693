import React from "react";
import Chart from "react-apexcharts";

const BarCharts = ({ id, count }) => {
    const state = {
        options: {
            chart: { id: "basic-bar" },
            xaxis: { categories: id },
        },
        series: [{ name: "core", data: count }],
    };

    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart">
                    <Chart options={state.options} series={state.series} type="bar" />
                </div>
            </div>
        </div>
    );
};

export default BarCharts;
