import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import REACT_APP from "../../../environment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { toast } from "react-toastify";

const InsuranceUnenroll = ({ open, setOpen, setIsWaiting, getData, id, setIdx, product_type_id }) => {
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setIdx(product_type_id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const unsubscribe = async () => {
        handleClose();
        setIsWaiting(true);
        await axios
            .delete(`${REACT_APP.API}/api/hrp/v1/insurance/enroll`, {
                headers: { Authorization: sessionStorage.getItem("token") },
                data: { id },
            })
            .then(async () => {
                await getData();
                toast.success(t("Success"));
            })
            .catch((err) => {
                console.log(err);
                toast.error(t("Something went wrong"));
            });
        setIsWaiting(false);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" className="my-2" onClick={handleClickOpen}>
                {t("Unenroll")}
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{t("Unenroll")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t("Are you sure you want to unenroll?")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("Cancel")}</Button>
                    <Button onClick={unsubscribe}>{t("Unenroll")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default InsuranceUnenroll;
