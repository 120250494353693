/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import PieChart from "./PieChart";
import Table from "./Table";
import axios from "axios";
import "../../HR/Performance/Dashboard/Dashboard.css";
import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../environment";

const Index = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [wrong, setWrong] = useState(true);

    const getPieData = async () => {
        await axios(`${REACT_APP.API}/api/hrp/v1/payroll/payroll/latest`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setWrong(false);
                setData(res.data.data);
            })
            .catch((err) => {
                if (err?.response?.data?.errors && err.response.data.errors[0].code === "hrp_emp_ntfnd_038") setNotFound(true);
                else toast.error(t("An error occurred"));
            });
    };

    useEffect(() => {
        getPieData();
    }, []);

    return (
        <div className="py-4 px-2">
            <h3>{t("Payrolls")}</h3>
            {notFound ? (
                <p style={{ fontSize: "1.2rem" }}>{t("You do not have a payroll yet")}</p>
            ) : wrong ? (
                <p style={{ fontSize: "1.2rem" }}>{t("No data available")}</p>
            ) : (
                <>
                    <div className="container" style={{ maxWidth: "550px" }}>
                        <PieChart item={data.current} />
                    </div>
                    <Table item={data} />
                </>
            )}
        </div>
    );
};

export default Index;
