import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import "./Location.css";
import axios from "axios";
import Spinner from "react-spinner-material";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

const Contact = () => {
    const { t } = useTranslation();
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [contact, setContact] = useState([]);
    const [address, setAddress] = useState([]);
    const [error, setError] = useState(false);
    const [successContact, setSuccessContact] = useState(false);
    const [successAddress, setSuccessAddress] = useState(false);
    const getContact = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/branch`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setSuccessContact(true);
                setLoading1(false);
                setContact(res.data.data);
            })
            .catch((err) => {
                setError(true);
                setLoading2(false);
            });
    };
    const getAddress = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/branch`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setSuccessAddress(true);
                setLoading2(false);
                setAddress(res.data.data.address_of_branch);
            })
            .catch((err) => {
                setError(true);
                setLoading2(false);
            });
    };
    useEffect(() => {
        getContact();
        getAddress();
    }, []);
    return (
        <>
            <h3>{t("Work contact info and work location")}</h3>
            {error && (
                <Alert variant="outlined" severity="error">
                    {t("An error occurred")}
                </Alert>
            )}
            {!error && contact.length === 0 && address.length === 0 && !loading1 && !loading2 && (
                <Alert variant="outlined" severity="warning">
                    {t("No Data yet")}
                </Alert>
            )}
            {loading1 && loading2 ? (
                <div
                    style={{
                        marginTop: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <div className="row">
                    <div className="column">
                        {successContact ? (
                            <div className="card">
                                <img src={require("../../../../images/phoneEmail.jpg")} alt="phoneEmail" />
                                <div className="container">
                                    <h4>{t("Work Contact Info")}</h4>
                                    <p>
                                        {t("Name")}: {contact.name}
                                    </p>
                                    <p>
                                        {t("Phone")}: {contact.phone === null ? <span>{t("No phone number")}</span> : contact.phone}
                                    </p>
                                    <p>
                                        {t("Email")}: {contact.email === null ? <span>{t("No email address")}</span> : contact.email}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="column">
                        {successAddress ? (
                            <div className="card">
                                <img src={require("../../../../images/address.jpg")} alt="Address" />
                                <div className="container">
                                    <h4>{t("Work Location")}</h4>
                                    <p>
                                        {t("Address1")}: {address.address1 === null ? <span>{t("No address 1")}</span> : address.address1}
                                    </p>
                                    <p>
                                        {t("Address2")}: {address.address2 === null ? <span>{t("No address 2")}</span> : address.address2}
                                    </p>
                                    <p>
                                        {t("City")}: {address.city === null ? <span>{t("No city name")}</span> : address.city}
                                    </p>
                                    <p>
                                        {t("Country")}: {address.country === null ? <span>{t("No country name")}</span> : address.country}
                                    </p>
                                    <p>
                                        {t("Region")}: {address.region === null ? <span>{t("No region name")}</span> : address.region}
                                    </p>
                                    <p>
                                        {t("Zip Code")}: {address.zip_code === null ? <span>{t("No zip code")}</span> : address.zip_code}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Contact;
