import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import Spinner from "react-spinner-material";
import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ id, UpdatedGoals }) {
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [loading, setLoading] = React.useState(false);
    const [wait, setWait] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getData = async () => {
        setWait(true);
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/goal/performance/${id}`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setWait(false);
                setData(res.data.data);
            })
            .catch((err) => {
                toast.error("An error occurred");
                setWait(false);
                console.log(err);
            });
    };

    const validateCompany = Yup.object({
        goal_name: Yup.string().required("goal name is required"),
        goal_details: Yup.string().required("goal details is required"),
    });

    const emptyData = data;

    const onUpdate = async (values) => {
        delete values.created_at;
        delete values.end_date;
        delete values.start_date;
        setLoading(true);
        await axios
            .put(`${REACT_APP.API}/api/hrp/v1/goal/performance/${id}`, values, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success("Goal updated successfully");
                handleClose();
                UpdatedGoals();
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error("An error occurred");
            });
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="addcompany">
            {loading && <div className="hrp-overlay"></div>}
            <EditOutlinedIcon onClick={handleOpen} />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter company information")}
                        </Typography>
                        <Formik
                            initialValues={emptyData}
                            validationSchema={validateCompany}
                            onSubmit={async (values) => {
                                onUpdate(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    {wait ? (
                                        <div
                                            style={{
                                                marginTop: "100px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                                        </div>
                                    ) : (
                                        <>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field fullWidth name="goal_name" label={t("Goal name")} autoComplete="goal_name" component={TextField} />
                                                </Grid>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        label={t("Goal details")}
                                                        name="goal_details"
                                                        component={TextField}
                                                        autoComplete="goal_details"
                                                        type="text"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div className="right_left res">
                                                <div>
                                                    <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error" className="mt-5">
                                                        {t("Close")}
                                                    </Button>
                                                </div>
                                                <div>
                                                    {loading ? (
                                                        <LoadingButton
                                                            type="submit"
                                                            loading
                                                            variant="contained"
                                                            sx={{ mt: 3, mb: 2 }}
                                                            color="success"
                                                            className="mt-5"
                                                        >
                                                            {t("Update")}
                                                        </LoadingButton>
                                                    ) : (
                                                        <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                            {t("Update")}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
