import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Modal from "@mui/material/Modal";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import OutboundIcon from "@mui/icons-material/Outbound";
import { Box } from "@mui/material";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import axios from "axios";
import { toast } from "react-toastify";
import EditTimeOff from "./EditTimeOff";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

export default function ActionMenu({ row, handleCloseMenu, setOverlay, getAllVacations }) {
    const { t } = useTranslation();
    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => {
        handleCloseMenu();
        setOpenEdit(false);
    };

    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => {
        handleCloseMenu();
        setOpenDelete(false);
    };
    const deleteTimeOff = () => {
        setOverlay(true);
        axios
            .delete(`${REACT_APP.API}/api/hrp/v1/vacation/${row.id}`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then(() => {
                handleCloseMenu();
                getAllVacations();
                setOverlay(false);
            })
            .catch((err) => {
                setOverlay(false);
                handleCloseMenu();
                toast.error(t("Something went wrong"));
            });
    };
    const submitTimeOff = () => {
        setOverlay(true);
        axios
            .post(`${REACT_APP.API}/api/hrp/v1/vacation/${row.id}/submit`, {}, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then(() => {
                setOverlay(false);
                getAllVacations();
                handleCloseMenu();
            })
            .catch((err) => {
                setOverlay(false);
                handleCloseMenu();
                toast.error("Something went wrong");
            });
    };
    return (
        <>
            <MenuItem onClick={handleOpenEdit}>
                <ListItemIcon>
                    <RefreshIcon color="action" />
                </ListItemIcon>
                {t("Edit")}
            </MenuItem>
            <Modal open={openEdit} onClose={handleCloseEdit} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="box-style">
                    <EditTimeOff handleClose={handleCloseEdit} row={row} setOverlay={setOverlay} getAllVacations={getAllVacations} />
                </Box>
            </Modal>
            <MenuItem onClick={handleOpenDelete}>
                <ListItemIcon>
                    <DeleteIcon color="error" />
                </ListItemIcon>
                {t("Delete")}
            </MenuItem>
            <Modal open={openDelete} onClose={handleCloseDelete} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="box-style-2 p-5">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <h6>
                                {t("Do you want to delete this")} ({row.start_date?.slice(0, 10)}/{row.end_date?.slice(0, 10) || row.hour} -
                                {row.vacation_request_type}) timeoff?
                            </h6>
                        </Grid>
                        <Grid item xs={12} sm={8} />
                        <Grid item xs={12} sm={2}>
                            <Button variant="contained" onClick={handleCloseDelete} color="inherit">
                                {t("No")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button variant="contained" color="error" onClick={deleteTimeOff}>
                                {t("YES")}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <MenuItem onClick={submitTimeOff}>
                <ListItemIcon>
                    <OutboundIcon color="primary" />
                </ListItemIcon>
                {t("Submit")}
            </MenuItem>
        </>
    );
}
