import React from "react";
import { useTranslation } from "react-i18next";

const Table = ({ item }) => {
    const { t } = useTranslation();

    return (
        <div className="table-responsive fs-6">
            <table className="table table-bordered container my-5">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">{t("Pay date")}</th>
                        <th scope="col">{t("Start date")}</th>
                        <th scope="col">{t("End date")}</th>
                        <th scope="col">{t("Employee Tax")}</th>
                        <th scope="col">{t("Company Tax")}</th>
                        <th scope="col">{t("Total Pay")}</th>
                        <th scope="col">{t("Amount")}</th>
                        <th scope="col">{t("Net Amount")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{t("Current")}</th>
                        <td>{item.current?.payment_date?.slice(0, 10)}</td>
                        <td>{item.current?.begin_date?.slice(0, 10)}</td>
                        <td>{item.current?.end_date?.slice(0, 10)}</td>
                        <td>{parseFloat(item.current?.employee_tax)}</td>
                        <td>{parseFloat(item.current?.company_tax)}</td>
                        <td>{parseFloat(item.current?.amount)}</td>
                        <td>{parseFloat(item.current?.total_amount)}</td>
                        <td>{parseFloat(item.current?.net_amount)}</td>
                    </tr>
                    <tr>
                        <th scope="row">YTD</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{parseFloat(item.ytd?.employee_tax)}</td>
                        <td>{parseFloat(item.ytd?.company_tax)}</td>
                        <td>{parseFloat(item.ytd?.amount)}</td>
                        <td>{parseFloat(item.ytd?.total_amount)}</td>
                        <td>{parseFloat(item.ytd?.net_amount)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Table;
