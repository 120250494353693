import React from "react";
import Chart from "react-apexcharts";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";

const PieChart = ({ item }) => {
    const { t } = useTranslation();
    const state = {
        series: [
            item?.net_amount ? parseFloat(item?.net_amount) : 0,
            item?.employee_tax ? parseFloat(item?.employee_tax) : 0,
            +item?.company_tax ? parseFloat(item?.company_tax) : 0,
        ],
        options: {
            chart: { width: 380, type: "pie" },
            labels: ["Net amount", "Employee tax", "Company tax"],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: { width: 200 },
                        legend: { position: "bottom" },
                    },
                },
            ],
        },
    };

    return (
        <div className="donut">
            {item && <Chart id="donut" options={state.options} series={state.series} type="pie" />}
            <div className="d-flex justify-content-between align-items-center">
                <div></div>
                <h6 className="text-center text-primary">
                    {t("Total amount")} : {item?.total_amount}
                </h6>
                <Modal id={item?.id} />
            </div>
        </div>
    );
};

export default PieChart;
