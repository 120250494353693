import React, { useEffect, useState } from "react";
import "./Contact.css";
import axios from "axios";
import Spinner from "react-spinner-material";
import EditContact from "./EditContact";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

const Contact = () => {
    const { t } = useTranslation();
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [contact, setContact] = useState([]);
    const [address, setAddress] = useState([]);
    const getContact = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/employee/contact`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading1(false);
                setContact(res.data.data);
            })
            .catch((err) => {
                setLoading1(false);
            });
    };
    const getAddress = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/employee/address`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading2(false);
                setAddress(res.data.data);
            })
            .catch((err) => {
                setLoading2(false);
            });
    };
    useEffect(() => {
        getContact();
        getAddress();
    }, []);
    return (
        <>
            {loading1 && loading2 ? (
                <div
                    style={{
                        marginTop: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <div className="row">
                    <div className="column">
                        <div className="card">
                            <img src={require("../../../../images/phoneEmail.jpg")} alt="phoneEmail" />
                            {contact.map((item) => {
                                return (
                                    <div className="container">
                                        <h4>{t("Personal phone and email address")}</h4>
                                        <p>
                                            {t("Home Phone")}: {item.home_phone}
                                        </p>
                                        <p>
                                            {t("Cell Phone")}: {item.cell_phone}
                                        </p>
                                        <p>
                                            {t("Personal Email")}: {item.email}
                                        </p>
                                        <EditContact getContact={getContact} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="column">
                        <div className="card">
                            <img src={require("../../../../images/address.jpg")} alt="Address" />
                            {address.map((item) => {
                                return (
                                    <div className="container">
                                        <h4>{t("Address")}</h4>
                                        <p>
                                            {t("Country")}: {item.country}
                                        </p>
                                        <p>
                                            {t("Address 1")}: {item.address1}
                                        </p>
                                        <p>
                                            {t("Address 2")}: {item.address2}
                                        </p>
                                        <p>
                                            {t("City")}: {item.city}
                                        </p>
                                        <p>
                                            {t("Region")}: {item.region}
                                        </p>
                                        <p>
                                            {t("Zip Code")}: {item.zip_code}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(Contact);
