import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Field, Form, Formik } from "formik";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";
import { toast } from "react-toastify";
import { Grid, MenuItem } from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TabModal() {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [scores, setScores] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const validate = Yup.object({
        performance_score_id: Yup.string().required(t("Field is required")),
        comment: Yup.string()
            .required(t("Field is required"))
            .matches(regex.description, { message: t("Enter valid description") }),
    });

    const getScores = async () => {
        await axios(`${REACT_APP.API}/api/hrp/v1/goal/performance_score`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => setScores(res.data.data))
            .catch((err) => console.log(err));
    };

    const onSubmit = async (values) => {
        setLoading(true);
        await axios
            .post(`${REACT_APP.API}/api/hrp/v1/goal/performance_score`, values, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Successfully"));
                handleClose();
            })
            .catch((err) => {
                setLoading(false);
                toast.error("An error occurred");
            });
    };

    useEffect(() => {
        getScores();
    }, []);

    return (
        <div>
            {loading && <div className="hrp-overlay"></div>}
            <Button onClick={handleOpen} variant="contained">
                {t("Add score")}
            </Button>

            <Modal
                open={open}
                slots={{ backdrop: Backdrop }}
                onClose={handleClose}
                closeAfterTransition
                aria-labelledby="transition-modal-title"
                slotProps={{ backdrop: { timeout: 500 } }}
                aria-describedby="transition-modal-description"
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter value")}
                        </Typography>
                        <Formik
                            initialValues={{ performance_score_id: "", comment: "" }}
                            validationSchema={validate}
                            onSubmit={async (values) => onSubmit(values)}
                        >
                            {({ errors, handleBlur, handleChange }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Field select fullWidth label={t("Score")} name="performance_score_id" component={TextField}>
                                                {scores.map((el) => (
                                                    <MenuItem key={el.id} value={el.id}>
                                                        {el.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label className="ms-2 mb-1" style={errors.comment && { color: "#d32f2f" }}>
                                                {t("Comment")}
                                            </label>
                                            <Field
                                                as="textarea"
                                                label={t("Score")}
                                                name="comment"
                                                rows="3"
                                                className={`form-control ${errors.comment ? "is-invalid" : ""}`}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.comment && <div className="err_comment">{errors.comment}</div>}
                                        </Grid>
                                    </Grid>
                                    <div className="right_left mt-0">
                                        <Button onClick={handleClose} variant="contained" color="error" className="mt-5">
                                            {t("Close")}
                                        </Button>
                                        <Button type="submit" className="mt-5" variant="contained" color="success">
                                            {t("Submit")}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TabModal);
