/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TablePagination, Tooltip } from "@mui/material";
import "./TimeOffHistory.css";
import axios from "axios";
import Spinner from "react-spinner-material";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

export default function TimeOffHistory() {
    const { t } = useTranslation();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getAllVacations();
    }, []);

    const getAllVacations = () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/vacation/requests`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                // convert response
                res.data.data.forEach((vac) => {
                    let summ = 0;
                    let start_date = vac.items[0].date;
                    let end_date = vac.items[0].date;
                    vac.items.forEach((item) => {
                        summ += item.hour;
                        if (start_date > item.date) start_date = item.date;
                        if (end_date < item.date) end_date = item.date;
                    });
                    vac.start_date = start_date;
                    vac.end_date = end_date;
                    vac.hour = summ;
                    vac.days = vac.items.length;
                });
                setData(res.data.data);
                setRows(res.data.data.slice(0, rowsPerPage));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    // paginition
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState(data.slice(0, rowsPerPage));
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setRows(data.slice(0, parseInt(event.target.value, 10)));
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setRows(data.slice(newPage * rowsPerPage, (newPage + 1) * rowsPerPage));
    };

    return (
        <div className="time-off-history">
            <ToastContainer />
            <Box m={5}>
                {loading && (
                    <div className="spinner21">
                        <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                    </div>
                )}
                {!loading && data.length !== 0 && (
                    <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">№</TableCell>
                                        <TableCell align="center">{t("Status")}</TableCell>
                                        <TableCell align="center">{t("Start date")}</TableCell>
                                        <TableCell align="center">{t("How many days")}</TableCell>
                                        <TableCell align="center">{t("Total hours")}</TableCell>
                                        <TableCell align="center">{t("End date")}</TableCell>
                                        <TableCell align="center">{t("Type")}</TableCell>
                                        <TableCell align="center">{t("Description")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, i) => (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="right">{data.indexOf(row) + 1}</TableCell>
                                            <TableCell align="center">{row.status}</TableCell>

                                            <TableCell align="center">{row.start_date?.slice(0, 10)}</TableCell>
                                            <TableCell align="center">{row.days}</TableCell>
                                            <TableCell align="center">{row.hour}</TableCell>
                                            <TableCell align="center">{row.end_date === row.start_date ? "" : row.end_date?.slice(0, 10)}</TableCell>
                                            <TableCell align="center">{row.vacation_request_type}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title={row.description}>
                                                    <span>{row.description}</span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="page">
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </>
                )}
                {!loading && data.length === 0 && <h4>{t("There is no information")}</h4>}
            </Box>
        </div>
    );
}
