import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Spinner from "react-spinner-material";
import Alert from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import TurnedInNotRoundedIcon from "@mui/icons-material/TurnedInNotRounded";
import EditGoal from "./EditGoal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import REACT_APP from "../../../../environment";

const CoreData = ({ id, name, image, description }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [approvedGoals, setApprovedGoals] = React.useState([]);
    const [submited, setSubmited] = React.useState([]);
    const [goals, setGoals] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [loading2, setLoading2] = React.useState(false);
    const [waiting, setWaiting] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const validate = Yup.object({
        goal_name: Yup.string().required(t("Goal name is required")),
        goal_details: Yup.string().required(t("Goal details is required")),
    });

    const getData = () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/recognition/core/image/${image}`, {
                responseType: "blob",
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((response) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (response.data.type === "application/pdf" || /image/.test(response.data.type)) {
                        window.document.getElementById("testt").src = `${event.target.result}`;
                    }
                };
                reader.readAsDataURL(response.data);
                setLoading(false);
            })
            .catch((err) => {
                toast.error("An error occurred");
                setError(true);
                setLoading(false);
            });
    };

    const getGoals = () => {
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/goal/performance?core_value_id=${id}&status=new`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => setGoals(res.data.data.goals))
            .catch((err) => toast.error("An error occurred"));
    };

    const ApprovedGoals = () => {
        setWaiting(true);
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/goal/performance?core_value_id=${id}&status=approved`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setWaiting(false);
                setApprovedGoals(res.data.data.goals);
            })
            .catch((err) => {
                setWaiting(false);
                toast.error("An error occurred");
            });
    };

    const getSubmittedGoals = () => {
        setWaiting(true);
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/goal/performance?core_value_id=${id}&status=submitted`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setWaiting(false);
                setSubmited(res.data.data.goals);
            })
            .catch((err) => {
                setWaiting(false);
                toast.error("An error occurred");
            });
    };

    const handleSubmit = (event, { resetForm }) => {
        setLoading2(true);
        axios
            .post(
                `${REACT_APP.API}/api/hrp/v1/goal/performance`,
                {
                    core_value_id: id,
                    goal_name: event.goal_name,
                    goal_details: event.goal_details,
                },
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                resetForm({ values: "" });
                getGoals();
                setExpanded(false);
                setLoading2(false);
                toast.success("Successfully created goal");
            })
            .catch((err) => {
                toast.error("An error occurred");
                setLoading2(false);
            });
    };

    const onDelete = (e) => {
        setWaiting(true);
        axios
            .delete(`${REACT_APP.API}/api/hrp/v1/goal/performance/${e}`, { headers: { Authorization: sessionStorage.getItem(`token`) } })
            .then((res) => {
                getGoals();
                setWaiting(false);
                toast.success("Successfully deleted goal");
            })
            .catch((err) => {
                setWaiting(false);
                toast.error("An error occurred");
            });
    };

    const onSubmit = (e) => {
        setWaiting(true);
        axios
            .patch(`${REACT_APP.API}/api/hrp/v1/goal/performance/${e}/status/submit`, {}, { headers: { Authorization: sessionStorage.getItem(`token`) } })
            .then((res) => {
                getGoals();
                getSubmittedGoals();
                setWaiting(false);
                toast.success("Successfully submited goal");
            })
            .catch((err) => {
                setWaiting(false);
                toast.error("An error occurred");
            });
    };

    const onDetails = (id) => {
        navigate(`/hr/performance/core-value/${id}`);
    };

    React.useEffect(() => {
        getData();
        getGoals();
        getSubmittedGoals();
        ApprovedGoals();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            {(loading2 || waiting) && <div className="hrp-overlay"></div>}
            {loading ? (
                <div style={{ marginTop: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <div>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        {name}
                    </Typography>

                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        {description ? description : "Description is currently null"}
                    </Typography>
                    {error ? (
                        <Alert variant="outlined" severity="error">
                            {t("There was an error taking the picture")}
                        </Alert>
                    ) : (
                        <img id="testt" title="test" alt="test" />
                    )}
                    <Accordion expanded={expanded}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => setExpanded(!expanded)}
                        >
                            <Button variant="contained" color="success">
                                {t("Create new goal")}
                            </Button>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Formik
                                initialValues={{ goal_name: "", goal_details: "" }}
                                validationSchema={validate}
                                onSubmit={async (values, { resetForm }) => {
                                    handleSubmit(values, { resetForm });
                                    return new Promise((res) => setTimeout(res, 500));
                                }}
                            >
                                {({ values, errors, resetForm }) => (
                                    <Form autoComplete="off" className="mt-4">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field fullWidth name="goal_name" label={t("Goal name")} autoComplete="goal_name" component={TextField} />
                                            </Grid>

                                            <Grid item xs={12} className="input_div">
                                                <Field
                                                    fullWidth
                                                    label={t("Goal details")}
                                                    name="goal_details"
                                                    component={TextField}
                                                    autoComplete="goal_details"
                                                    type="text"
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className="right_left res">
                                            <div>
                                                {loading2 ? (
                                                    <LoadingButton
                                                        loading
                                                        loadingPosition="start"
                                                        startIcon={<SaveIcon />}
                                                        variant="contained"
                                                        sx={{ mt: 2, mb: 2 }}
                                                    >
                                                        {t("Save")}
                                                    </LoadingButton>
                                                ) : (
                                                    <Button type="submit" className="mt-4" variant="contained" sx={{ mt: 2, mb: 2 }}>
                                                        {t("Save")}
                                                    </Button>
                                                )}
                                            </div>
                                            <Button variant="contained" color="error" onClick={() => setExpanded(!expanded)}>
                                                {t("Close")}
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}

            {goals.length ? (
                <>
                    <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ marginTop: "25px" }}>
                        {t("Saved goals")}
                    </Typography>
                    <List className="core_list" style={{ marginTop: "20px" }}>
                        {goals.map((item) => {
                            return (
                                <>
                                    <Divider />
                                    <ListItem
                                        className="list_item"
                                        secondaryAction={
                                            <>
                                                <IconButton className="core_icon" aria-label="delete" onClick={() => onSubmit(item.id)}>
                                                    <TurnedInNotRoundedIcon />
                                                </IconButton>
                                                <IconButton className="core_icon" aria-label="delete">
                                                    <EditGoal id={item.id} UpdatedGoals={ApprovedGoals} />
                                                </IconButton>
                                                <IconButton className="core_icon" aria-label="delete" onClick={() => onDelete(item.id)}>
                                                    <DeleteOutlineRoundedIcon />
                                                </IconButton>
                                            </>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FolderIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={item.goal_name} />
                                        {/* <ListItemText primary={item.goal_details?.length > 50 ? item.goal_details.slice(0, 50) + "..." : item.goal_details} /> */}
                                    </ListItem>
                                </>
                            );
                        })}
                    </List>
                </>
            ) : null}

            {submited.length ? (
                <>
                    <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ marginTop: "25px" }}>
                        {t("Submitted goals")}
                    </Typography>
                    <List className="core_list" style={{ marginTop: "20px" }}>
                        {submited.map((item) => {
                            return (
                                <>
                                    <Divider />
                                    <ListItem className="list_item">
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FolderIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={item.goal_name} />
                                        {/* <ListItemText primary={item.goal_details?.length > 50 ? item.goal_details.slice(0, 50) + "..." : item.goal_details} /> */}
                                    </ListItem>
                                </>
                            );
                        })}
                    </List>
                </>
            ) : null}
            {approvedGoals.length ? (
                <>
                    <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ marginTop: "25px" }}>
                        {t("Approved goals")}
                    </Typography>
                    <List className="core_list" style={{ marginTop: "20px" }}>
                        {approvedGoals.map((item) => {
                            return (
                                <>
                                    <Divider />
                                    <ListItem
                                        className="list_item"
                                        secondaryAction={
                                            <Button onClick={() => onDetails(item.id)} variant="contained">
                                                {t("Details")}
                                            </Button>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <FolderIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={item.goal_name} />
                                        {/* <ListItemText primary={item.goal_details?.length > 50 ? item.goal_details.slice(0, 50) + "..." : item.goal_details} /> */}
                                    </ListItem>
                                </>
                            );
                        })}
                    </List>
                </>
            ) : null}
        </div>
    );
};

export default React.memo(CoreData);
