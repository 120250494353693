import Joi from "joi";
import regex from "tts-hrp-val-lib";

const apply = Joi.object({
    vacation_request_type_id: Joi.number()
        .integer()
        .required()
        .error((errors) => {
            console.log(errors[0].message);
            if (errors[0].code === "number.base") {
                errors[0].message = "Select timeoff type";
            } else errors[0].message = "Something went wrong";
            return errors;
        }),
    description: Joi.string().optional().allow(""),
    items: Joi.array()
        .items(
            Joi.object({
                date: Joi.string().regex(regex.date_YYYYMMDD).required(),
                hour: Joi.number().min(0).max(16).required(),
            })
        )
        .required()
        .error((errors) => {
            console.log(errors[0].message);
            errors[0].message = "Something went wrong";
            return errors;
        }),
});

export default apply;
