import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../environment";

const ChangePassword = ({ setChange }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const [loading, setLoading] = useState(false);

    const Submit = async (data) => {
        setLoading(true);
        axios
            .put(`${REACT_APP.API}/api/hrp/v1/me/password`, data, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                toast.success(t("Successfully changed!"));
                setChange(false);
                reset();
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    return (
        <form onSubmit={handleSubmit(Submit)} className="row">
            {loading && <div className="hrp-overlay"></div>}
            <div className="col-md-12 mb-2">
                <label>{t("Old password")}</label>
                <input
                    type="password"
                    disabled={loading}
                    {...register("oldPassword", { required: true })}
                    className={errors.oldPassword ? "is-invalid form-control" : "form-control"}
                />
            </div>
            <div className="col-md-6 mb-2">
                <label>{t("New password")}</label>
                <input
                    type="password"
                    disabled={loading}
                    {...register("password", { required: true, minLength: 6 })}
                    className={errors.password ? "is-invalid form-control" : "form-control"}
                />
            </div>
            <div className="col-md-6 mb-2">
                <label>{t("Repeat password")}</label>
                <input
                    type="password"
                    disabled={loading}
                    {...register("rePassword", { required: true, minLength: 6 })}
                    className={errors.rePassword ? "is-invalid form-control" : "form-control"}
                />
            </div>
            <div className="col-12 mt-3 d-flex float-end chng">
                <button disabled={loading} className="btn btn-secondary mb-2" onClick={() => setChange(false)}>
                    {t("Cancel")}
                </button>
                <button disabled={loading} type="submit" className="btn btn-primary mb-2 ms-2">
                    {loading && <CircularProgress style={{ color: "white", margin: "0 3px -2px 0" }} size="0.9rem" />} {t("Change")}
                </button>
            </div>
        </form>
    );
};

export default ChangePassword;
