import React from "react";
import BigCalendar from "./Calendar";
import "./Calendar.css";

const Index = () => {
    return (
        <div id="big_cal">
            <BigCalendar />
        </div>
    );
};

export default Index;
