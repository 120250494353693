/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { AppstoreOutlined, SafetyOutlined, AuditOutlined } from "@ant-design/icons";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button, Layout, Menu, theme } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import styles from "./MainNavigation.module.css";
import avatarImg from "../images/avatar.jpg";
import en from "../images/en.png";
import uz from "../images/uz.png";
import Cookies from "js-cookie";
import Welcome from "../components/Home/Welcome";
import Calendar from "../components/Calendar/Index";
import Survays from "../components/Survays/MySurvays/Survays";
import Vacancies from "../components/HR/Vacancies";
import Employment from "../components/HR/work/Employment/Employment";
import Holidays from "../components/HR/Holidays/index";
import Location from "../components/HR/work/Location/Location";
import Contact from "../components/HR/personal/contact/Contact.jsx";
import Dependents from "../components/HR/personal/dependents/Dependents";
import EmergencyContacts from "../components/HR/personal/EmergencyContacts/EmergencyContacts";
import Sensitive from "../components/HR/personal/Sensitive/Sensitive";
import Insurence from "../components/HR/Benefits/Insurence/Insurence";
import Retirement from "../components/HR/Benefits/Retirement/Retirement";
import Reviews from "../components/HR/Performance/Reviews/Reviews";
import Feedback from "../components/HR/Performance/Feedback/Feedback";
import DevelopmentGoals from "../components/HR/Performance/DevelopmentGoals";
import Dashboard from "../components/HR/Performance/Dashboard/Dashboard";
import TimeOffHistory from "../components/HR/TimeOff/TimeOffHistory/TimeOffHistory";
import TimeOffScreen from "../components/HR/TimeOff/TimeOffScreen/TimeOffScreen";
import RecognitionCenter from "../components/HR/Recognition/Recognition Center/Recognition";
import RecognitionScreen from "../components/HR/Recognition/Recognition/Recognition";
import Rates from "../components/Payroll/Rates/RatesTable";
import Tax from "../components/Payroll/Taxes/Tax";
import Payrolls from "../components/Payroll/Payrolls/index";
import Deductions from "../components/Payroll/Deductions/Deductions";
import Dashboards from "../components/Survays/Dashboard/Dashboard";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CoreValue from "./HR/Performance/CoreValue/Index";
import EmploymentChart from "./HR/Employment/employment";
import Profile from "./Profile/Profile";
import axios from "axios";
import Education from "./HR/personal/Education";
import Certification from "./HR/personal/Certification";
import Skills from "./HR/personal/Skills";
import WorkHistory from "./HR/personal/WorkHistory/WorkHistoryTable";
import Address from "./HR/personal/Address";
import Notification from "./Notification/notification";
import notificationStyle from "./Notification/styles.module.css";
import { createMessage } from "../CheckContext";
import REACT_APP from "../environment";
import { useContext } from "react";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { Select } from "@mui/material";
import CoreValueDetails from "./HR/Performance/CoreValue/CoreValueDetails";
import setCookie from "../hooks/cookie/setCookie.jsx";
import DevGoalDetails from "./HR/Performance/DevelopmentGoals/DevGoalDetails.jsx";
import Department from "./HR/work/department/DepartmentPage.js";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import UpdateIcon from "@mui/icons-material/Update";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import CompanyBigCalendar from "./Company-big-calendar/Index.jsx";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import InsuranceCenter from "./Benefits/InsuranceCenter/Index.jsx";
import InsuranceEnrollment from "./Benefits/InsuranceEnrollment/Index.jsx";
import InsuranceEnrollmentDetails from "./Benefits/InsuranceEnrollment/Details.jsx";
import WorkCalendar from "./Calendar-Work/Index.jsx";

const { Header, Sider, Content } = Layout;
function getItem(label, key, icon, children, type) {
    return { key, icon, children, label, type };
}

const App = () => {
    const { t, i18n } = useTranslation();

    const location = useLocation();
    const [messages, setMessages] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [sidemodal, setViewSideModal] = useState(false);
    const [message, setMessage] = useContext(createMessage);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [selectedKeys, setSelectedKeys] = useState("/hr/contact");
    const [lang, setlang] = useState(Cookies.get("i18next"));
    const [img, setImg] = useState(null);
    const [items, setItems] = useState([]);
    const [logo, setLogo] = useState(null);

    const onLanguage = (e) => {
        const lng = e.target.value;
        i18n.changeLanguage(lng);
        setlang(lng);
        firstAPI();
    };

    const getData = async () => {
        setImg(null);
        axios(`${REACT_APP.AUTH}/api/hrp/v2/employee/info`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then((res) => {
            if (res.data.data) {
                axios(`${REACT_APP.API}/api/hrp/v1/me/image`, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                    responseType: "blob",
                })
                    .then((res) => setImg(URL.createObjectURL(res.data)))
                    .catch((err) => console.log(err));
            }
        });
    };

    const firstAPI = async () => {
        axios(`${REACT_APP.AUTH}/api/hrp/v2/employee/accesses`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        }).then((res) => {
            sessionStorage.setItem("branchPresence", res.data.data.branch_presence);
            setCookie("lc", res.data.data.last_company, 30);
            setItems([
                getItem(res.data.data.home, "/", <div />),
                getItem(t("Home"), "/home", <HomeIcon className="m_icons" />),
                getItem(t("HR"), "/", <ManageAccountsIcon className="m_icons" />, [
                    getItem(t("Calendar"), "/hr/big-calendar", <EditCalendarIcon />, [
                        getItem(t("Department Calendar"), "/hr/big-calendar/department"),
                        getItem(t("Company Calendar"), "/hr/big-calendar/company"),
                        getItem(t("Work Calendar"), "/hr/calendar"),
                    ]),
                    getItem(t("Personal"), "/hr/personal", <AppstoreOutlined />, [
                        getItem(t("Contact"), "/hr/personal/contact"),
                        getItem(t("Dependents"), "/hr/personal/dependents"),
                        getItem(t("Emergency contacts"), "/hr/personal/emergency-contact"),
                        getItem(t("Sensitive"), "/hr/personal/sensitive"),
                        getItem(t("Address"), "/hr/personal/address"),
                    ]),
                    getItem(t("Work"), "/hr/work", <AppstoreOutlined />, [
                        getItem(t("Location"), "/hr/work/location"),
                        getItem(t("Employment"), "/hr/work/employment"),
                        getItem(t("Department & Position"), "/hr/work/department-position"),
                        getItem(t("Holidays"), "/hr/work/holidays"),
                    ]),
                    getItem(t("Performance"), "/hr/performance", <TrendingUpIcon />, [
                        getItem(t("Dashboard"), "/hr/performance/dashboard"),
                        getItem(t("Core Value"), "/hr/performance/core-value"),
                        getItem(t("Goals"), "/hr/performance/developmentGoals"),
                        getItem(t("Feedback"), "/hr/performance/feedback"),
                        getItem(t("Reviews"), "/hr/performance/reviews"),
                    ]),
                    getItem(t("Time Off"), "/hr/time-of", <UpdateIcon />, [
                        getItem(t("Time Off"), "/hr/time-off"),
                        getItem(t("Time off history"), "/hr/time-off/history"),
                    ]),
                    getItem(t("Recognitions"), "/hr/recognition", <WorkspacePremiumIcon />, [
                        getItem(t("Recognition center"), "/hr/recognition/center"),
                        getItem(t("Recognition screen"), "/hr/recognition/screen"),
                    ]),

                    getItem(t("Employment"), "/hr/employment"),
                    getItem(t("Pay"), "/hr/pay"),
                    getItem(t("Training"), "/hr/training"),
                    getItem(t("Vacancies"), "/hr/vacancies"),
                    res.data.data.apps.find((e) => e === "timesheet") && getItem(t("Timesheet"), "/timesheet"),
                ]),
                getItem(t("Benefits"), "/hr/benefits", <TipsAndUpdatesIcon />, [
                    getItem(t("Insurance"), "/benefits/insurance", <SafetyOutlined />, [
                        getItem(t("Insurance"), "/benefits/insurances"),
                        getItem(t("Enrollment"), "/benefits/insurance/enrollment"),
                    ]),
                    getItem(t("Retirement"), "/hr/benefits/retirement", <AuditOutlined />),
                ]),
                getItem(t("Payroll"), "/payroll", <AccountBalanceWalletIcon className="m_icons" />, [
                    getItem(t("Payrolls"), "/payroll/payrolls"),
                    getItem(t("Rates"), "/payroll/rates"),
                    getItem(t("Taxes"), "/payroll/taxes"),
                    getItem(t("Earnings"), "/payroll/earnings"),
                    getItem(t("Deductions"), "/payroll/deductions"),
                    getItem(t("dDeposit"), "/payroll/deposit"),
                    getItem(t("lAllocation"), "/payroll/lAllocation"),
                ]),

                getItem(t("Surveys"), "/surveys", <RequestQuoteIcon className="m_icons" />, [
                    getItem(t("My surveys"), "/surveys/my-surveys"),
                    getItem(t("Dashboard"), "/surveys/dashboard"),
                ]),
                getItem(t("Career Center"), "career-center", <WorkIcon className="m_icons" />, [
                    getItem(t("Education"), "/career-center/education"),
                    getItem(t("Certification"), "/career-center/certification"),
                    getItem(t("Skills"), "/career-center/skills"),
                    getItem(t("Work History"), "/career-center/work-history"),
                ]),
            ]);
        });
    };

    async function getLogo() {
        await fetch(`${REACT_APP.AUTH}/api/hrp/v2/employee/logo`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            method: "GET",
        })
            .then((res) => res.blob())
            .then((blob) => {
                if (blob.type?.slice(0, 5) === "image") {
                    setLogo(window.URL.createObjectURL(blob));
                }
            })
            .catch((err) => console.log(err));
    }

    const getNotifications = async () => {
        axios(`${REACT_APP.API}/api/hrp/v1/notification`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setMessages(res.data.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        getNotifications();
    }, [message]);

    useEffect(() => {
        firstAPI();
        getLogo();
        getData();
        getNotifications();
    }, []);

    useEffect(() => {
        const pathName = location.pathname;
        setSelectedKeys(pathName);
    }, [location.pathname]);

    const navigate = useNavigate();

    return (
        <Layout>
            {sidemodal && <Notification setShow={setViewSideModal} show={sidemodal} messages={messages} />}
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ overflow: "auto", height: "100vh" }}>
                <div className="logo" />
                <Menu
                    onClick={(item) => {
                        if (item.key === "/timesheet")
                            setTimeout(() => {
                                window.open(`${REACT_APP.LOGIN}/login?url=${REACT_APP.TIMESHEET_URL}`, "_blank");
                            }, 300);
                        else navigate(item.key);
                    }}
                    defaultOpenKeys={["Personal"]}
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={collapsed}
                    selectedKeys={[selectedKeys]}
                    items={items}
                />
            </Sider>
            <Layout>
                <Header theme="dark" className="header" style={{ background: colorBgContainer }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{ fontSize: "16px", width: 64, height: 64 }}
                        />
                        {logo && <img src={logo} alt="logo" height={64} style={{ height: "64px" }} />}
                    </div>

                    <div className="d-flex align-items-center">
                        <div className="me-4">
                            <Select value={lang} size="small" onChange={onLanguage}>
                                <MenuItem value="en">
                                    <img style={{ width: "23px", marginRight: "7px" }} src={en} alt="en" /> en
                                </MenuItem>
                                <MenuItem value="uz">
                                    <img style={{ width: "23px", marginRight: "7px" }} src={uz} alt="en" /> uz
                                </MenuItem>
                            </Select>
                        </div>
                        <div
                            className={notificationStyle.icon}
                            onClick={() => {
                                setViewSideModal(!sidemodal);
                            }}
                        >
                            <NotificationsIcon sx={{ fontSize: "28px" }} />
                            {messages.length > 0 && <div className={notificationStyle.tooltip}>{messages.length}</div>}
                        </div>
                        <div className="dropdown me-4">
                            <a
                                className="dropdown-toggle d-flex align-items-center hidden-arrow"
                                href="#/"
                                id="navbarDropdownMenuAvatar"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img src={img ? img : avatarImg} className="rounded-circle" style={{ width: "35px", height: "35px" }} alt="avatar" />
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuAvatar">
                                <li>
                                    <NavLink className={styles.dropdownItem} to="/profile">
                                        {t("My profile")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className={styles.dropdownItem} to="/settings">
                                        {t("Settings")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={styles.dropdownItem}
                                        to="/login"
                                        onClick={() => {
                                            sessionStorage.clear();
                                            window.onbeforeunload = {};
                                            window.location.replace(`${REACT_APP.LOGIN}/login?url=${window.location.href}logout`);
                                        }}
                                    >
                                        {t("Logout")}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Header>
                <Content id="head_content" style={{ background: colorBgContainer, overflowY: "auto" }}>
                    <Routes>
                        <Route path="/home" exact element={<Welcome />} />
                        <Route path="/" element={<EmploymentChart />} />
                        <Route path="/profile" exact element={<Profile getAvatar={getData} />} />

                        {/* HR */}
                        <Route path="/hr/personal/contact" element={<Contact />} />
                        <Route path="/hr/personal/dependents" element={<Dependents />} />
                        <Route path="/hr/personal/emergency-contact" element={<EmergencyContacts />} />
                        <Route path="/hr/personal/sensitive" element={<Sensitive />} />
                        <Route path="/hr/personal/address" element={<Address />} />

                        <Route path="/hr/work/employment" element={<Employment />} />
                        <Route path="hr/work/department-position" element={<Department />} />
                        <Route path="/hr/work/location" element={<Location />} />
                        <Route path="/hr/work/holidays" element={<Holidays />} />
                        <Route path="/hr/calendar" element={<WorkCalendar />} />
                        <Route path="/hr/big-calendar/department" element={<Calendar />} />
                        <Route path="/hr/big-calendar/company" element={<CompanyBigCalendar />} />

                        <Route path="/benefits/insurances" element={<InsuranceCenter />} />
                        <Route path="/benefits/insurance/enrollment" element={<InsuranceEnrollment />} />
                        <Route path="/benefits/insurance/enrollment/:id" element={<InsuranceEnrollmentDetails />} />
                        <Route path="/hr/benefits/retirement" element={<Retirement />} />

                        <Route path="/hr/performance/dashboard" element={<Dashboard />} />
                        <Route path="/hr/performance/developmentGoals" element={<DevelopmentGoals />} />
                        <Route path="/hr/performance/developmentGoals/:id" element={<DevGoalDetails />} />
                        <Route path="/hr/performance/feedback" element={<Feedback />} />
                        <Route path="/hr/performance/reviews" element={<Reviews />} />

                        <Route path="/hr/performance/core-value" element={<CoreValue />} />
                        <Route path="/hr/performance/core-value/:id" element={<CoreValueDetails />} />

                        <Route path="/hr/time-off" element={<TimeOffScreen />} />
                        <Route path="/hr/time-off/history" element={<TimeOffHistory />} />

                        <Route path="/hr/recognition/center" element={<RecognitionCenter />} />
                        <Route path="/hr/recognition/screen" element={<RecognitionScreen />} />

                        <Route path="/hr/pay" element={<h1>pay</h1>} />
                        <Route path="/hr/training" element={<h1>training</h1>} />
                        <Route path="/hr/vacancies" element={<Vacancies />} />

                        {/* PAYROLL */}
                        <Route path="/payroll/payrolls" element={<Payrolls />} />
                        <Route path="/payroll/rates" element={<Rates />} />
                        <Route path="/payroll/taxes" element={<Tax />} />
                        <Route path="/payroll/earnings" element={<h1>earnings</h1>} />
                        <Route path="/payroll/deductions" element={<Deductions />} />
                        <Route path="/payroll/deposit" element={<h1>dDeposit</h1>} />
                        <Route path="/payroll/lAllocation" element={<h1>lAllocation</h1>} />

                        {/* SURVEYS */}
                        <Route path="surveys/my-surveys" element={<Survays />} />
                        <Route path="surveys/dashboard" element={<Dashboards />} />

                        {/* career center */}
                        <Route path="/career-center/education" element={<Education />} />
                        <Route path="/career-center/certification" element={<Certification />} />
                        <Route path="/career-center/skills" element={<Skills />} />
                        <Route path="/career-center/work-history" element={<WorkHistory />} />
                        <Route path="*" element={<Navigate replace to="/home" />} />
                    </Routes>
                </Content>
            </Layout>
        </Layout>
    );
};
export default App;
