/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import SearchIcon from "@mui/icons-material/Search";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { Empty, Spin } from "antd";
import REACT_APP from "../../../environment";
import axios from "axios";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const headCells = [
        { id: "name", numeric: true, label: t("Holiday Name") },
        { id: "date", numeric: true, label: t("Date") },
        { id: "created_by", numeric: true, label: t("Created By") },
    ];

    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ fontWeight: "bold", fontSize: "15px" }}
                        key={headCell.id}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {" "}
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = { orderBy: PropTypes.string.isRequired, rowCount: PropTypes.number.isRequired };

function Index() {
    const [orderBy, setOrderBy] = React.useState("calories");
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState("asc");
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const { t } = useTranslation();

    React.useEffect(() => {
        getDate();
    }, []);

    const getDate = async () => {
        setErrorMsg(null);
        setLoading(true);
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/holiday?limit=1000`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data.holidays);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(err.message);
                toast.error("An error occurred!");
                setLoading(false);
            });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <div className="d-flex justify-content-between row py-3 px-md-3">
                <div className="col-sm-6 col-md-8">
                    <h4 className="text-center text-sm-start">{t("Holidays")}</h4>
                </div>
                <div className="col-sm-6 col-md-4 position-relative">
                    <input style={{ paddingLeft: "35px" }} type="text" className="form-control" placeholder={t("Search")} />
                    <SearchIcon sx={{ position: "absolute", top: "8px", left: "20px", color: "#aaa", fontSize: "23px" }} />
                </div>
            </div>
            {loading ? (
                <div style={{ height: "70vh" }} className="d-flex justify-content-center align-items-center">
                    <Spin tip="Loading" size="large">
                        <div className="content" />
                    </Spin>
                </div>
            ) : errorMsg ? (
                <p>Error</p>
            ) : data.length === 0 ? (
                <Empty />
            ) : (
                <div className="px-md-4">
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead order={order} onRequestSort={handleRequestSort} orderBy={orderBy} rowCount={data.length} />
                                    <TableBody>
                                        {stableSort(data, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={row.id}>
                                                        <TableCell align="left">{row.name}</TableCell>
                                                        <TableCell align="left">{new Date(row.date).toLocaleDateString()}</TableCell>
                                                        <TableCell align="left">{row.created_by?.first_name}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                page={page}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                component="div"
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </div>
            )}
        </>
    );
}

export default Index;
