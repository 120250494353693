import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useState } from "react";
import REACT_APP from "../../environment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
import "./Calendar.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CompanyBigCalendar = () => {
    const [events, setEvents] = useState([]);

    const getData = async () => {
        await axios(`${REACT_APP.API}/api/hrp/v1/employee/calendar/company`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                res.data.data?.forEach((el) => {
                    el.start = new Date(el.start);
                    el.end = new Date(el.end);
                    el.tooltip = el.title;
                    el.title = (
                        <p style={{ height: "10px" }}>
                            {el.title} <span id="f_name">{el.first_name?.slice(0, 1) + "." + el.last_name}</span>
                        </p>
                    );
                });
                setEvents(res.data.data);
            })
            .catch(() => toast.error("An error occurred getting events"));
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div id="big-calendar">
            <div className="my-calendar">
                <DnDCalendar
                    localizer={localizer}
                    events={events}
                    tooltipAccessor={"tooltip"}
                    startAccessor="start"
                    endAccessor="end"
                    longPressThreshold={10}
                    eventPropGetter={() => ({
                        style: {
                            backgroundColor: "rgba(80, 130, 180, 0.3)",
                            color: "black",
                            borderLeft: "3px solid rgb(80, 130, 180)",
                            borderRadius: "0",
                            fontFamily: "monospace",
                        },
                    })}
                />
            </div>
        </div>
    );
};

export default CompanyBigCalendar;
