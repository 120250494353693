import React from "react";
import { useTranslation } from "react-i18next";

const SURVEYS = [
    {
        id: "1",
        tax: "sample text",
        filingStatus: "sample text",
        dependents: "sample text",
        otherIncome: "sample text",
        deductions: "sample text",
        type: "sample text",
        amount: "sample text",
    },
    {
        id: "2",
        tax: "sample text",
        filingStatus: "sample text",
        dependents: "sample text",
        otherIncome: "sample text",
        deductions: "sample text",
        type: "sample text",
        amount: "sample text",
    },
    {
        id: "3",
        tax: "sample text",
        filingStatus: "sample text",
        dependents: "sample text",
        otherIncome: "sample text",
        deductions: "sample text",
        type: "sample text",
        amount: "sample text",
    },
];

const Survey = () => {
    const { t } = useTranslation();

    return (
        <div className="container mt-3 cntnr2">
            <h2 className="h2">{t("My surveys")}</h2>
            <div className="table-responsive">
                <table data-testid="table" className="table table-bordered">
                    <thead data-testid="thead">
                        <tr>
                            <th>Survay Name</th>
                            <th>Status</th>
                            <th>Participants</th>
                            <th>Launch Date</th>
                            <th>Dure Date</th>
                            <th>Completion Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody data-testid="tbody">
                        {SURVEYS.map((SURVEY) => (
                            <tr key={SURVEY.id}>
                                <td>{SURVEY.tax}</td>
                                <td>{SURVEY.filingStatus}</td>
                                <td>{SURVEY.dependents}</td>
                                <td>{SURVEY.otherIncome}</td>
                                <td>{SURVEY.deductions}</td>
                                <td>{SURVEY.type}</td>
                                <td>{SURVEY.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Survey;
