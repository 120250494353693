/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { createMessage } from "../../../../CheckContext";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ getData }) {
    const { t } = useTranslation();
    const [message, setMessage] = useContext(createMessage);
    const [relations, setRelations] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validateCompany = Yup.object({
        first_name: Yup.string()
            .required(t("First name is required"))
            .matches(regex.first_name, { message: t("Enter valid first name") }),
        last_name: Yup.string()
            .required(t("Last name is required"))
            .matches(regex.last_name, { message: t("Enter valid last name") }),
        phone: Yup.string()
            .required(t("Phone is required"))
            .matches(regex.phone, { message: t("Enter valid phone") }),
        email: Yup.string().email().required(t("Email is required")),
        type_of_relationship: Yup.string().required(t("Type of relationship is required")),
    });
    const emptyData = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        type_of_relationship: "",
    };

    const onSubmit = async (values) => {
        setLoading(true);
        await axios
            .post(`${REACT_APP.API}/api/hrp/v1/emergency-contact`, values, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Emergency added successfully"));
                handleClose();
                getData();
                setMessage((prev) => !prev);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("An error occurred"));
            });
    };

    const getRelections = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/emergency-contact/type`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setRelations(res.data.data);
            })
            .catch((err) => {
                toast.error(t("An error occurred"));
            });
    };

    useEffect(() => {
        getRelections();
    }, []);

    return (
        <div className="addcompany">
            {loading && <div className="hrp-overlay"></div>}
            <Button onClick={handleOpen} variant="contained">
                {t("Add Emergency contact")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter emergency contact")}
                        </Typography>
                        <Formik
                            initialValues={emptyData}
                            validationSchema={validateCompany}
                            onSubmit={async (values) => {
                                onSubmit(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} className="marginBottom">
                                            <Field
                                                fullWidth
                                                label={t("First name")}
                                                name="first_name"
                                                component={TextField}
                                                autoComplete="first_name"
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth name="last_name" label={t("Last name")} autoComplete="last_name" component={TextField} />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth name="email" label={t("Email")} autoComplete="email" component={TextField} />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth name="phone" label={t("Phone")} autoComplete="phone" component={TextField} />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth select name="type_of_relationship" component={TextField} label={t("Select relationship")}>
                                            {relations.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.id} key={index}>
                                                        {item.relationship}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Field>
                                    </Grid>
                                    <div className="right_left">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error" className="mt-5">
                                            {t("Close")}
                                        </Button>
                                        <>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success" className="mt-5">
                                                    {t("Submit")}
                                                </LoadingButton>
                                            ) : (
                                                <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {t("Submit")}
                                                </Button>
                                            )}
                                        </>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
