import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import en from "./en.json";
import uz from "./uz.json";

i18n.use(initReactI18next)
    .use(detector)
    .init({
        resources: {
            en: { translation: en },
            uz: { translation: uz },
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        detection: { order: ["cookie", "localstorage"], caches: ["cookie"] },
    });

export default i18n;
