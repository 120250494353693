import React from "react";
import Tabs from "./Tabs.jsx";
import "./style.css";

const CoreValue = () => {
    return (
        <div className="core">
            <Tabs />
        </div>
    );
};

export default CoreValue;
