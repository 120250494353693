import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    //   height: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
// --max_old_space_size=10240

function TransitionsModal({ data }) {
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [applayed, setApplayed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const applyJob = async () => {
        setLoading(true);
        await axios
            .post(
                `https://tts-hrp-core-employee-api.herokuapp.com/api/hrp/v1/open-position/apply/${data.row.id}`,
                {},
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                }
            )
            .then((res) => {
                setLoading(false);
                setApplayed(true);
                setError(false);
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
            });
    };
    return (
        <div>
            {loading && <div className="hrp-overlay"></div>}
            <Button onClick={handleOpen}>{t("Read more")}</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="container py-5 Popup">
                        {error ? (
                            <Alert variant="outlined" severity="warning">
                                {t("You have already applied this job")}
                            </Alert>
                        ) : (
                            ""
                        )}
                        {applayed ? <Alert severity="success">{t("This job was applied successfully")}</Alert> : ""}
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {data?.row?.name}
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <Typography variant="h6" component="h1" style={{ display: "inline", fontSize: "18px" }}>
                                {t("Description")}:
                            </Typography>
                            {data?.row?.full_job_description}
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <Typography variant="h6" component="h1" style={{ display: "inline", fontSize: "18px" }}>
                                {t("Employee arrangement")}:
                            </Typography>
                            {data?.row?.employee_arrangement}
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <Typography variant="h6" component="h1" style={{ display: "inline", fontSize: "18px" }}>
                                {t("Requirement")}:
                            </Typography>
                            {data?.row?.requirement}
                        </Typography>
                        <div className="right_left">
                            <Button variant="outlined" color="success" onClick={applyJob}>
                                {t("Apply")}
                            </Button>
                            <Button variant="outlined" color="error" onClick={handleClose}>
                                {t("Close")}
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
