/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TablePagination, Tooltip } from "@mui/material";
import "../TimeOffHistory/TimeOffHistory.css";
import axios from "axios";
import Spinner from "react-spinner-material";
import { toast } from "react-toastify";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ActionMenu from "./TimeOffTableActionMenu";
import CreateTimeOff from "./CreateTimeOff";
import { GetData } from "./Context";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

export default function ActiveTimeOff(props) {
    const { t } = useTranslation();
    const [message] = useContext(GetData);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // get data
    const { loading, setLoading } = props;
    const [data, setData] = React.useState([]);
    const [iserror, setiserror] = React.useState(false);

    React.useEffect(() => {
        getAllVacations();
    }, [message]);

    const getAllVacations = () => {
        setLoading(true);
        setiserror(false);
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/vacation/requests`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                let result = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i].status === "new" || res.data.data[i].status === "updated") result.push(res.data.data[i]);
                }
                // convert response
                result.forEach((vac) => {
                    let summ = 0;
                    let start_date = vac.items[0].date;
                    let end_date = vac.items[0].date;
                    vac.items.forEach((item) => {
                        summ += item.hour;
                        if (start_date > item.date) start_date = item.date;
                        if (end_date < item.date) end_date = item.date;
                    });
                    vac.start_date = start_date;
                    vac.end_date = end_date;
                    vac.hour = summ;
                    vac.days = vac.items.length;
                });
                setData(result);
                setRows(result.slice(0, rowsPerPage));
                setLoading(false);
            })
            .catch((err) => {
                setiserror(true);
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    // paginition
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = React.useState(data.slice(0, rowsPerPage));
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setRows(data.slice(0, parseInt(event.target.value, 10)));
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setRows(data.slice(newPage * rowsPerPage, (newPage + 1) * rowsPerPage));
    };
    const [currentRow, setCurrentRow] = React.useState(null);

    return (
        <div>
            <Box m={5}>
                {loading && (
                    <div className="spinner21">
                        <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                    </div>
                )}
                {!loading && data.length !== 0 && (
                    <>
                        <h3 style={{ textAlign: "center", marginBottom: "1em" }}>{t("Active requests of timeoff")}</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">№</TableCell>
                                        <TableCell align="center">{t("Start date")}</TableCell>
                                        <TableCell align="center">{t("End date")}</TableCell>
                                        <TableCell align="center">{t("Total hours")}</TableCell>
                                        <TableCell align="center">{t("Type")}</TableCell>
                                        <TableCell align="center">{t("Description")}</TableCell>
                                        <TableCell align="center">{t("Action")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, i) => (
                                        <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell align="center">{data.indexOf(row) + 1}</TableCell>
                                            <TableCell align="center">{row.start_date?.slice(0, 10)}</TableCell>
                                            <TableCell align="center">{row.end_date?.slice(0, 10)}</TableCell>
                                            <TableCell align="center">{row.hour}</TableCell>
                                            <TableCell align="center">{row.vacation_request_type}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title={row.description}>
                                                    <span>{row.description}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    onClick={(event) => {
                                                        setCurrentRow(row);
                                                        handleClick(event);
                                                    }}
                                                    size="small"
                                                    aria-controls={open ? "account-menu" : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? "true" : undefined}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="page">
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </>
                )}
                {!loading && data.length === 0 && <h4>{t("There are no active requests of timeoff")}</h4>}
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <ActionMenu row={currentRow} handleCloseMenu={handleClose} setOverlay={props.setOverlay} getAllVacations={getAllVacations} />
            </Menu>
        </div>
    );
}
