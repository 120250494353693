import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { DataGrid } from "@mui/x-data-grid";
import Spinner from "react-spinner-material";

import Popup from "./Popup";
import { Container, Item, Input, SpinnerWrapper } from "./style";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../environment";

const Vacancies = () => {
    const { t } = useTranslation();

    const columns = [
        { field: "id", headerName: "ID", width: 100, align: "center" },
        { field: "city", headerName: t("City"), width: 200, align: "center" },
        {
            field: "country",
            headerName: t("Country"),
            type: "number",
            width: 200,
            align: "center",
        },
        {
            field: "job_type",
            headerName: t("Job type"),
            type: "number",
            width: 200,
            align: "center",
        },
        {
            field: "name",
            headerName: t("Name"),
            type: "number",
            width: 200,
            align: "center",
        },
        {
            field: "type",
            headerName: t("Type"),
            type: "number",
            width: 200,
            align: "center",
        },
        {
            field: "action",
            headerName: t("Action"),
            width: 200,
            align: "center",
            renderCell: (data) => {
                return (
                    <div>
                        <Popup data={data} />
                    </div>
                );
            },
        },
    ];

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [input, setInput] = useState("");

    const onSearch = async (e) => {
        if (input) {
            setLoading(true);
            await axios
                .get(`${REACT_APP.API}/api/hrp/v1/open-position/search?query=${input}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setData(res.data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };
    const onChange = (e) => {
        setInput(e.target.value);
    };
    const getData = async () => {
        setSpinner(true);
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/open-position/search`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setData(res.data.data);
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            {loading && <div className="hrp-overlay"></div>}
            <Container>
                <Item input>
                    <Input className="form-control" placeholder={t("Search jobs...")} onChange={onChange} />
                    {loading ? (
                        <LoadingButton loading variant="outlined" style={{ marginLeft: "10px", marginRight: "10px" }}>
                            {t("Search")}
                        </LoadingButton>
                    ) : (
                        <Button onClick={() => onSearch()} style={{ marginLeft: "10px", marginRight: "10px" }} variant="outlined" size="large">
                            {t("Search")}
                        </Button>
                    )}
                </Item>

                <Item table>
                    {data.length > 0 && <h3 style={{ background: "white" }}>List of vacancies:</h3>}
                    {!spinner ? (
                        <div>
                            {!data.length ? (
                                <div></div>
                            ) : (
                                <div style={{ height: 87 * (data.length > 9 ? 10 : data.length + 1) }}>
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        pageSizeOptions={[5, 10, 25, 100]}
                                        checkboxSelection={false}
                                        disableSelectionOnClick={true}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <SpinnerWrapper>
                            <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                        </SpinnerWrapper>
                    )}
                </Item>
            </Container>
        </div>
    );
};

export default React.memo(Vacancies);
