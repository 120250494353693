const NODE_ENV = process.env.REACT_APP_ENV || "production";
// scp -r build root@159.223.217.246:/var/www/employee

const modes = {
    development: {
        AUTH: "http://188.166.84.222:9100",
        API: "http://159.223.205.127:9103",
        DOC_CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
        DOC_SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
        LOGIN: "http://174.138.2.211:9100",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
        TIMESHEET_URL: "http://143.244.173.81:9102",
    },
    production: {
        AUTH: "https://api.ishkunim.uz",
        API: "https://api3.ishkuni.uz",
        DOC_CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
        DOC_SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
        LOGIN: "https://ishkunim.uz",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
        TIMESHEET_URL: "https://tms.ishkuni.uz",
    },
};

export default modes[NODE_ENV] || {
    AUTH: "http://188.166.84.222:9100",
    API: "http://159.223.205.127:9103",
    DOC_CLIENT_ID: "398970e4cfa7449eb02f68c7d181412343e4b82f838b5aa72691584e958c41c2",
    DOC_SECRET: "1~BNt'[TN!1|%1W71*54>=S9&^AYaG(k,}'B6C8),Yj=zMkeCdHu8[H\"|GBE;'du",
    LOGIN: "http://174.138.2.211:9100",
    COMMON_API: "https://tts-common-api.herokuapp.com",
    COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
    COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
    TIMESHEET_URL: "http://143.244.173.81:9102",
};
