import EduTable from "./EduTable";

const Education = () => {
    return (
        <div className="container">
            <EduTable />
        </div>
    );
};

export default Education;
