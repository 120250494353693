/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Box, Button } from "@mui/material";
import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import "./recognition.css";
import axios from "axios";
import { useEffect } from "react";
import Results from "./results";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

function RecognitionCenter() {
    const { t } = useTranslation();
    const [date, setDate] = useState("all time");
    const [member, setMember] = useState(0);
    const [departments, setDepartments] = useState([]);
    const navigate = useNavigate();
    const [coreValues, setCoreValues] = useState([]);
    const [selectCV, setSelectCV] = React.useState(0);

    useEffect(() => {
        getDepartments();
        getAllCoreValues();
    }, []);

    const getAllCoreValues = () => {
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/recognition/core`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setCoreValues(res.data.data);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
            });
    };
    const handleChangeDate = (event) => {
        setDate(event.target.value);
    };

    const handleChangeMember = (event) => {
        setMember(event.target.value);
    };

    const getDepartments = () => {
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/organization/departments`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setDepartments(res.data.data);
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
            });
    };

    return (
        <div>
            <ToastContainer />
            <Box m={5}>
                <h2>{t("Recognition center")}</h2>
                <div style={{ display: "flex", justifyContent: "flex-end" }} className="create-recognition">
                    <Button
                        variant="outlined"
                        sx={{ margin: 0 }}
                        onClick={() => {
                            navigate("/hr/recognition/create");
                        }}
                    >
                        {t("Recognize a team member")}
                    </Button>
                </div>
                <div className="r-con-flb">
                    <div className="rb-select">
                        <Box sx={{ maxWidth: 360, width: "100%", bgcolor: "background.paper" }}>
                            <h4>{t("Core values")}</h4>
                            <nav aria-label="main mailbox folders">
                                <List className="cvs">
                                    {coreValues.length > 0 && (
                                        <ListItem
                                            disablePadding
                                            className={selectCV === 0 ? "activecv" : ""}
                                            onClick={() => {
                                                setSelectCV(0);
                                            }}
                                        >
                                            <ListItemButton>
                                                <ListItemIcon>{selectCV === 0 && <DoneAllIcon color="primary" />}</ListItemIcon>
                                                <ListItemText primary="All" />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    {coreValues.map((item) => (
                                        <ListItem
                                            key={item.id}
                                            disablePadding
                                            className={selectCV === item.id ? "activecv" : ""}
                                            onClick={() => {
                                                setSelectCV(item.id);
                                            }}
                                        >
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {(selectCV === item.id || selectCV === 0) && <DoneAllIcon color="primary" />}
                                                    <img id={"cv" + item.id} alt="cv" style={{ display: "none" }} />
                                                </ListItemIcon>
                                                <ListItemText primary={item.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </nav>
                        </Box>
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-helper-label">{t("Department")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={member}
                                label={t("Department")}
                                onChange={handleChangeMember}
                            >
                                <MenuItem value={0}>{t("My Self")}</MenuItem>
                                {departments.map((item) => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-helper-label">{t("Time")}</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={date}
                                label={t("Time")}
                                onChange={handleChangeDate}
                            >
                                <MenuItem value="this week">{t("This week")}</MenuItem>
                                <MenuItem value="this month">{t("This month")}</MenuItem>
                                <MenuItem value="this quarter">{t("This quarter")}</MenuItem>
                                <MenuItem value="this year">{t("This year")}</MenuItem>
                                <MenuItem value="last year">{t("Last year")}</MenuItem>
                                <MenuItem value="all time">{t("All time")}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </Box>
            <Results date={date} member={member} core={selectCV} coreValues={coreValues} />
        </div>
    );
}

export default React.memo(RecognitionCenter);
