/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Card, CardContent, CardMedia, CircularProgress, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import FolderIcon from "@mui/icons-material/Folder";
import Typography from "@mui/material/Typography";
import REACT_APP from "../../../../environment";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import Spinner from "react-spinner-material";
import Alert from "@mui/material/Alert";
import { toast } from "react-toastify";
import * as React from "react";
import axios from "axios";

const CoreValueDetails = () => {
    const params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const commentRef = React.useRef();
    const [data, setData] = React.useState([]);
    const [goal, setGoal] = React.useState(null);
    const [error, setError] = React.useState(false);
    const [comment, setComment] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [imgLoading, setImgLoading] = React.useState(true);
    const [sendLoading, setSendLoading] = React.useState(false);
    const [commentLoading, setCommentLoading] = React.useState(false);

    const getData = async () => {
        setLoading(true);
        await axios(`${REACT_APP.API}/api/hrp/v1/goal/performance/${params.id}`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                const elem = res.data.data;
                setGoal(elem);
                axios(`${REACT_APP.API}/api/hrp/v1/recognition/core/${elem.core_value_id}`, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                    .then((res) => {
                        setData(res.data.data);
                        setImgLoading(true);
                        setLoading(false);

                        axios(`${REACT_APP.API}/api/hrp/v1/recognition/core/image/${res.data.data.image}`, {
                            responseType: "blob",
                            headers: { Authorization: sessionStorage.getItem("token") },
                        })
                            .then((response) => {
                                const reader = new FileReader();
                                reader.onload = (event) => {
                                    if (/image/.test(response.data.type)) window.document.getElementById("testt_img").src = `${event.target.result}`;
                                };
                                reader.readAsDataURL(response.data);
                                setImgLoading(false);
                            })
                            .catch((err) => {
                                toast.error("An error occurred");
                                setError(true);
                                setImgLoading(false);
                            });
                    })
                    .catch((err) => setLoading(false));
            })
            .catch((err) => setLoading(false));
    };

    const getComment = () => {
        setCommentLoading(true);
        axios(`${REACT_APP.API}/api/hrp/v1/goal/performance/${params.id}/comment`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setComment(res.data.data);
                setCommentLoading(false);
            })
            .catch((err) => {
                setComment([]);
                setCommentLoading(false);
                toast.error("An error occurred");
            });
    };

    const sendMessage = () => {
        const text = commentRef.current.value.trim();
        if (text) {
            setSendLoading(true);
            axios
                .post(
                    `${REACT_APP.API}/api/hrp/v1/goal/performance/comment`,
                    { employee_core_value_id: goal.id, text: text },
                    { headers: { Authorization: sessionStorage.getItem("token") } }
                )
                .then((res) => {
                    setSendLoading(false);
                    commentRef.current.value = "";
                    getComment();
                })
                .catch((err) => {
                    console.log(err);
                    setSendLoading(false);
                });
        }
    };

    const onStart = () => {
        axios
            .patch(
                `${REACT_APP.API}/api/hrp/v1/goal/performance/${goal.id}/status/progress`,
                {},
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => getData())
            .catch((err) => {});
    };

    const onFinish = () => {
        setLoading(true);
        axios
            .patch(
                `${REACT_APP.API}/api/hrp/v1/goal/performance/${goal.id}/status/complete`,
                {},
                { headers: { Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => onBack())
            .catch((err) => toast.error("An error occurred"));
    };

    const onBack = () => navigate("/hr/performance/core-value");

    React.useEffect(() => {
        getData();
        getComment();
    }, []);

    return (
        <div>
            {loading && <div className="hrp-overlay"></div>}
            {loading ? (
                <div style={{ marginTop: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <div className="p-md-5">
                    <div className="tabs_box_item mb-3" onClick={onBack}>
                        <ArrowBackIcon />
                        <Button size="small" className="ArrowBackIcon_btn" variant="outlined" color="error">
                            {t("Go back")}
                        </Button>
                        {goal?.status === "in_progress" && (
                            <Button variant="contained" onClick={onFinish} style={{ float: "right", marginRight: "10px" }}>
                                {t("Finish goal")}
                            </Button>
                        )}
                    </div>

                    <Card sx={{ maxWidth: 345 }}>
                        <div className="p-3">
                            {imgLoading ? (
                                <div className="p-5 mx-5">
                                    <CircularProgress />
                                </div>
                            ) : error ? (
                                <Alert variant="outlined" severity="error">
                                    {t("There was an error taking the picture")}
                                </Alert>
                            ) : (
                                <CardMedia component="img" width="300px" id="testt_img" alt="img" />
                            )}
                        </div>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {data.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {data.description}
                            </Typography>
                        </CardContent>
                    </Card>

                    <List className="core_list" style={{ marginTop: "20px" }}>
                        <Divider />
                        <ListItem
                            className="list_item"
                            secondaryAction={
                                goal.status === "approved" && (
                                    <Button onClick={() => onStart(goal.id)} variant="contained">
                                        Start
                                    </Button>
                                )
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={goal.goal_name} />
                            <ListItemText style={{ margin: "30px" }} primary={goal.goal_details} />
                        </ListItem>
                    </List>

                    {goal.status === "in_progress" && (
                        <>
                            <List className="core_list" style={{ marginTop: "20px" }}>
                                {comment.map((el) => (
                                    <>
                                        <Divider />
                                        <ListItem key={el.id} className="list_item">
                                            <p>{el.text}</p>
                                        </ListItem>
                                    </>
                                ))}
                            </List>
                            <div className="row g-3">
                                <div className="col-9 col-md-11">
                                    <textarea
                                        disabled={sendLoading}
                                        ref={commentRef}
                                        className="form-control"
                                        placeholder="Comment..."
                                        cols="30"
                                        rows="3"
                                    ></textarea>
                                </div>
                                <div className="col-3 col-md-1 d-flex justify-content-center">
                                    <Avatar
                                        disabled={sendLoading}
                                        onClick={sendMessage}
                                        sx={{ width: "50px", height: "50px", padding: "5px", cursor: "pointer" }}
                                    >
                                        <SendIcon />
                                    </Avatar>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default React.memo(CoreValueDetails);
