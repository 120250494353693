/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { createMessage } from "../../../../CheckContext";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ getData }) {
    const { t } = useTranslation();
    const [message, setMessage] = useContext(createMessage);
    const [empty, setEmpty] = React.useState({
        current: "",
        from_date: "",
        to_date: "",
        country: "Uzbekistan",
        city: "",
        region: "",
        address1: "",
        address2: "",
        zip_code: "",
    });
    const [country, setCountry] = React.useState([]);
    const [regions, setRegions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validate = Yup.object({
        country: Yup.string()
            .required(t("Country is required"))
            .matches(regex.country, { message: t("Enter valid country") }),
        city: Yup.string()
            .required(t("City is required"))
            .matches(regex.city, { message: t("Enter valid city") }),
        address1: Yup.string()
            .required(t("Address1 is required"))
            .matches(regex.address1, { message: t("Enter valid address1") }),
        address2: Yup.string()
            .optional(t("Address2 is optional"))
            .matches(regex.address2, { message: t("Enter valid address2") }),
        region: Yup.string()
            .required(t("Region is required"))
            .matches(regex.region, { message: t("Enter valid region") }),
        zip_code: Yup.string().optional().matches(regex.zip_code, { message: "Enter valid zip_code" }),
        current: Yup.string().required(t("Current is required")),
        from_date: Yup.string().required(t("From date is required")),
        to_date: Yup.date().when("current", (current, schema) => {
            if (current === false || current === "false") {
                return schema.required(t("To date is required"));
            }
            return schema.optional();
        }),
    });
    const emptyData = {
        current: "",
        from_date: "",
        to_date: "",
        country: "Uzbekistan",
        city: "",
        region: "",
        address1: "",
        address2: "",
        zip_code: "",
    };

    const onSubmit = async (values) => {
        if (!values?.to_date?.length || values?.current === true) delete values.to_date;
        if (!values?.zip_code?.length) delete values.zip_code;
        if (!values?.address2?.length) delete values.address2;

        setLoading(true);
        await axios
            .post(`${REACT_APP.API}/api/hrp/v1/employee/address`, values, {
                headers: { "Content-Type": "application/json", Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Added successfully"));
                handleClose();
                getData();
                setMessage((prev) => !prev);
                setEmpty(emptyData);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("An error occurred"));
                setEmpty(emptyData);
            });
    };

    const getCountry = async () => {
        await axios
            .get(`${REACT_APP.COMMON_API}/tts/api/v01/countries`, {
                headers: { "client-id": REACT_APP.COMMON_ID, secret: REACT_APP.COMMON_SECRET },
            })
            .then((res) => setCountry(res.data.data))
            .catch((err) => toast.error(t("An error occurred")));
    };
    const getRegions = () => {
        axios(`${REACT_APP.COMMON_API}/tts/api/v01/region/14ed6cfc-d787-45ba-b44d-84ac06e37935`, {
            headers: {
                "client-id": REACT_APP.COMMON_ID,
                secret: REACT_APP.COMMON_SECRET,
            },
        })
            .then((res) => setRegions(res.data.data))
            .catch((err) => {});
    };
    useEffect(() => {
        getCountry();
        getRegions();
    }, []);

    return (
        <div className="addcompany">
            {loading && <div className="hrp-overlay"></div>}
            <Button onClick={handleOpen} variant="contained">
                {t("Add Address")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal overflow_scroll">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter Address info")}
                        </Typography>
                        <Formik
                            initialValues={empty}
                            validationSchema={validate}
                            onSubmit={async (values) => {
                                onSubmit(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="">
                                    <Grid item xs={12} className="marginBottom">
                                        <label className="mb-1">
                                            <Field type="checkbox" name="current" />
                                            <span style={{ marginLeft: "5px", fontSize: "1.2rem" }}>{t("current")}</span>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field
                                            select
                                            fullWidth
                                            label={t("Select country")}
                                            name="country"
                                            disableCloseOnSelect={true}
                                            component={TextField}
                                            autoComplete="country"
                                        >
                                            {country.map((el) => (
                                                <MenuItem key={el.id} value={el.name}>
                                                    {el.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className="marginBottom">
                                        {values.country === "Uzbekistan" ? (
                                            <Field
                                                select
                                                fullWidth
                                                label={t("Select region")}
                                                name="region"
                                                disableCloseOnSelect={true}
                                                component={TextField}
                                                autoComplete="region"
                                                type="text"
                                            >
                                                {regions.map((el) => (
                                                    <MenuItem key={el.id} value={el.name}>
                                                        {el.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        ) : (
                                            <Field fullWidth name="region" label={t("Region")} component={TextField} autoComplete="region" type="text" />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth label={t("City")} name="city" component={TextField} autoComplete="city" type="text" />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth label={t("Address1")} name="address1" component={TextField} autoComplete="region" type="text" />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth label={t("Address2")} name="address2" component={TextField} autoComplete="address2" type="text" />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth label={t("Zip Code")} name="zip_code" component={TextField} autoComplete="zip_code" type="text" />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field
                                            fullWidth
                                            name="from_date"
                                            label={t("From date")}
                                            InputLabelProps={{ shrink: true }}
                                            component={TextField}
                                            autoComplete="zip_code"
                                            type="date"
                                        />
                                    </Grid>
                                    {values.current === false && (
                                        <Grid item xs={12} className="marginBottom">
                                            <Field
                                                fullWidth
                                                label={t("To date")}
                                                InputLabelProps={{ shrink: true }}
                                                name="to_date"
                                                component={TextField}
                                                autoComplete="zip_code"
                                                type="date"
                                            />
                                        </Grid>
                                    )}
                                    <div className="right_left">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error" className="mt-5">
                                            {t("Close")}
                                        </Button>
                                        <>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success" className="mt-5">
                                                    {t("Submit")}
                                                </LoadingButton>
                                            ) : (
                                                <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {t("Submit")}
                                                </Button>
                                            )}
                                        </>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
