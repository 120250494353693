/* eslint-disable no-unused-vars */
import React from "react";
import TimeOffTable from "./TimeOffTable";
import "./TimeOff.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CreateTimeOff from "./CreateTimeOff";
import { GetDataProvider } from "./Context";
import { useTranslation } from "react-i18next";

const TimeOffScreen = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [overlay, setOverlay] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <GetDataProvider>
            <div className="time-off-screen">
                {overlay && <div className="overlay"></div>}
                <div className="create-timeoff">
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box className="box-style">
                            <CreateTimeOff handleClose={handleClose} />
                        </Box>
                    </Modal>
                </div>
                <TimeOffTable loading={loading} setLoading={setLoading} setOverlay={setOverlay} />

                {!loading && (
                    <Button onClick={handleOpen} variant="contained" className="apply">
                        {t("Apply timeoff")}
                    </Button>
                )}
            </div>
        </GetDataProvider>
    );
};

export default TimeOffScreen;
