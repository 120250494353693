/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, CircularProgress, Fade, Grid, Modal, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { array, object, string } from "yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const WorkHistory = ({ getDataa }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const emptyWorkHistory = {
        name: "",
        position: "",
        start_date: "",
        end_date: "",
        phone: "",
        email: undefined,
        website: undefined,
        supervisor: { name: "", position: "", phone: "", email: "" },
        references: [
            { first_name: "", last_name: "", position: "", phone: "", email: "" },
            { first_name: "", last_name: "", position: "", phone: "", email: "" },
        ],
        address: {
            country: "",
            address1: "",
            address2: undefined,
            city: "",
            region: "",
            zip_code: undefined,
        },
    };

    const workHistorySchema = object({
        name: string()
            .matches(regex.company_name, { message: t("Invalid name") })
            .required(t("Name is required")),
        position: string()
            .matches(regex.work_position, { message: t("Invalid position") })
            .required(t("Position is required")),
        start_date: Yup.date().max(new Date(), t("Future date not allowed")).typeError(t("Invalid start date")).required(t("Start date is required")),
        end_date: Yup.date()
            .required(t("End date is required"))
            .when("start_date", (start_date, yup) => start_date && yup.min(start_date, t("End date cannot be before start date")))
            .max(new Date(), t("Future date not allowed")),
        email: string().email(t("Invalid email")).nullable(),
        phone: string()
            .matches(regex.phone, { message: t("Invalid number") })
            .required(t("Your phone is required")),
        website: string()
            .matches(regex.website, { message: t("Invalid website") })
            .nullable(),

        supervisor: object({
            name: string()
                .matches(regex.full_name, { message: t("Invalid name") })
                .required(t("Name is required")),
            position: string()
                .matches(regex.work_position, { message: t("Invalid position") })
                .required(t("Position is required")),
            phone: string()
                .matches(regex.phone, { message: t("Invalid number") })
                .required(t("Phone is required")),
            email: string().required(t("Email is required")).email(t("Invalid email")),
        }),

        references: array(
            object({
                first_name: string()
                    .matches(regex.first_name, { message: t("Invalid first name") })
                    .required(t("First name is required")),
                last_name: string()
                    .matches(regex.last_name, { message: t("Invalid last name") })
                    .required(t("Last name is required")),
                position: string()
                    .matches(regex.work_position, { message: t("Invalid position") })
                    .required(t("Position is required")),
                phone: string()
                    .matches(regex.phone, { message: t("Invalid number") })
                    .required(t("Phone is required")),
                email: string().required(t("Email is required")).email(t("Invalid email")),
            })
        ),

        address: object({
            country: string()
                .required(t("Country is required"))
                .matches(regex.country, { message: t("Invalid country") }),
            address1: string()
                .matches(regex.address1, { message: t("Invalid address 1") })
                .required(t("Address 1 is required")),
            address2: string()
                .matches(regex.address2, { message: t("Invalid address 2") })
                .nullable(),
            city: string()
                .matches(regex.city, { message: t("Invalid city") })
                .required(t("City is required")),
            region: string().required(t("Region is required")),
            zip_code: string()
                .matches(regex.zip_code, { message: t("Invalid ZipCode") })
                .nullable()
                .optional(),
        }),
    });

    const submit = (value) => {
        setLoading(true);
        if (!value?.website?.length) {
            delete value?.website;
        }
        if (!value?.address?.address2?.length) {
            delete value?.address?.address2;
        }
        if (!value?.address?.zip_code?.length) {
            delete value?.zip_code;
        }
        axios
            .post(`${REACT_APP.API}/api/hrp/v1/employee/workhistory`, value, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                handleClose();
                getDataa();
                toast.success(t("Saved successfully"));
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("An error occurred while saving work history!"));
            });
    };

    return (
        <div className="addcompany">
            {loading && <div className="hrp-overlay"></div>}
            <Button onClick={handleOpen} variant="contained">
                {t("Add Work History")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box className="res_modal overflow_scroll" sx={style}>
                        <Button onClick={handleClose} variant="contained" color="primary" style={{ float: "right" }}>
                            <CloseIcon />
                        </Button>
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter work history info")}
                        </Typography>
                        <Formik
                            initialValues={emptyWorkHistory}
                            validationSchema={workHistorySchema}
                            onSubmit={async (values) => {
                                submit(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors, isSubmitting }) => (
                                <Form autoComplete="off">
                                    <Grid container direction="column" spacing={2}>
                                        <React.Fragment>
                                            <Grid item container spacing={2} xs={12}>
                                                <Grid item xs={12} sm={4}>
                                                    <Field fullWidth label={t("Name")} name={`name`} component={TextField} autoComplete="name" type="text" />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Position")}
                                                        name={`position`}
                                                        component={TextField}
                                                        autoComplete="position"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field fullWidth label={t("Phone")} name={`phone`} component={TextField} autoComplete="phone" type="text" />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Website")}
                                                        name={`website`}
                                                        component={TextField}
                                                        autoComplete="website"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Field fullWidth label={t("Email")} name={`email`} component={TextField} autoComplete="email" type="text" />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <label>{t("Start date")}</label>
                                                    <Field fullWidth name={`start_date`} component={TextField} autoComplete="start_date" type="date" />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <label>{t("End date")}</label>
                                                    <Field fullWidth name={`end_date`} component={TextField} autoComplete="end_date" type="date" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <p className="text-center mt-3">{t("Supervisor")}</p>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Name")}
                                                        name={`supervisor.name`}
                                                        component={TextField}
                                                        autoComplete="name"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Position")}
                                                        name={`supervisor.position`}
                                                        component={TextField}
                                                        autoComplete="position"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Phone")}
                                                        name={`supervisor.phone`}
                                                        component={TextField}
                                                        autoComplete="phone"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Email")}
                                                        name={`supervisor.email`}
                                                        component={TextField}
                                                        autoComplete="email"
                                                        type="text"
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <p className="text-center mt-3">{t("References")}</p>
                                                </Grid>

                                                {values.references?.map((_, idx) => (
                                                    <Grid container item key={idx} spacing={2}>
                                                        <Grid item container spacing={2} xs={12}>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field
                                                                    fullWidth
                                                                    label={t("First name")}
                                                                    name={`references[${idx}].first_name`}
                                                                    component={TextField}
                                                                    autoComplete="first name"
                                                                    type="text"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field
                                                                    fullWidth
                                                                    label={t("Last name")}
                                                                    name={`references[${idx}].last_name`}
                                                                    component={TextField}
                                                                    autoComplete="last name"
                                                                    type="text"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <Field
                                                                    fullWidth
                                                                    label={t("Position")}
                                                                    name={`references[${idx}].position`}
                                                                    component={TextField}
                                                                    autoComplete="position"
                                                                    type="text"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <Field
                                                                    fullWidth
                                                                    label={t("Phone")}
                                                                    name={`references[${idx}].phone`}
                                                                    component={TextField}
                                                                    autoComplete="phone"
                                                                    type="text"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <Field
                                                                    fullWidth
                                                                    label={t("Email")}
                                                                    name={`references[${idx}].email`}
                                                                    component={TextField}
                                                                    autoComplete="Email"
                                                                    type="text"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}

                                                <Grid item xs={12}>
                                                    <p className="text-center mt-3">{t("Address")}</p>
                                                </Grid>

                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Address1")}
                                                        name={`address.address1`}
                                                        component={TextField}
                                                        autoComplete="address1"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Address2")}
                                                        name={`address.address2`}
                                                        component={TextField}
                                                        autoComplete="address2"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("City")}
                                                        name={`address.city`}
                                                        component={TextField}
                                                        autoComplete="address1"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Zip Code")}
                                                        name={`address.zip_code`}
                                                        component={TextField}
                                                        autoComplete="zip_code"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Country")}
                                                        name={`address.country`}
                                                        component={TextField}
                                                        autoComplete="country"
                                                        type="text"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Field
                                                        fullWidth
                                                        label={t("Region")}
                                                        name={`address.region`}
                                                        component={TextField}
                                                        autoComplete="region"
                                                        type="text"
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item>{typeof errors.work === "string" ? <Typography color="error">{errors.work}</Typography> : null}</Grid>
                                            <Grid item container spacing={2} xs={12}>
                                                <Grid item xs={6} sm={12} className="right">
                                                    <Button
                                                        className="right"
                                                        type="submit"
                                                        variant="contained"
                                                        startIcon={isSubmitting ? <CircularProgress size="0.9rem" /> : undefined}
                                                    >
                                                        {isSubmitting ? t("Saving") : t("Save")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
export default WorkHistory;
