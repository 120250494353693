/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import Spinner from "react-spinner-material";
import Alert from "@mui/material/Alert";
import AddSkillModal from "./AddSkillModal";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";
import "./style.css";

function EnhancedTable() {
    const { t } = useTranslation();
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const [getAll, setGetAll] = useState([]);
    const [expert, setExpert] = useState([]);
    const [medium, setMedium] = useState([]);
    const [beginner, setBeginner] = useState([]);
    const [advanced, setAdvanced] = useState([]);
    const [proficient, setProficient] = useState([]);

    const getData = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/employee/skills`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                setGetAll(res.data.data);
                setBeginner(res.data.data.filter((e) => e.level === 1));
                setAdvanced(res.data.data.filter((e) => e.level === 2));
                setMedium(res.data.data.filter((e) => e.level === 3));
                setProficient(res.data.data.filter((e) => e.level === 4));
                setExpert(res.data.data.filter((e) => e.level === 5));
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
            });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <div className="container">
            <h3>{t("Skills")}</h3>
            <AddSkillModal getData={getData} />
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%", marginTop: "20px" }}>
                    {!error && getAll.length === 0 && (
                        <Alert variant="outlined" severity="warning">
                            {t("No data yet")}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            {t("An error occurred")}
                        </Alert>
                    )}
                    {getAll.length !== 0 && (
                        <div className="form-group row g-3">
                            {beginner.length !== 0 && (
                                <div className="col-md-6 my-4">
                                    <label className="form-label skill-levels">Beginner</label>
                                    <div className="skill-board w-100 h-100 p-2">
                                        {beginner.map((el, i) => (
                                            <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                {el.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {advanced.length !== 0 && (
                                <div className="col-md-6 my-4">
                                    <label className="form-label skill-levels">Advanced</label>
                                    <div className="skill-board w-100 h-100 p-2">
                                        {advanced.map((el, i) => (
                                            <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                {el.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {medium.length !== 0 && (
                                <div className="col-md-6 my-4">
                                    <label className="form-label skill-levels">Medium</label>
                                    <div className="skill-board w-100 h-100 p-2">
                                        {medium.map((el, i) => (
                                            <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                {el.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {proficient.length !== 0 && (
                                <div className="col-md-6 my-4">
                                    <label className="form-label skill-levels">Proficient</label>
                                    <div className="skill-board w-100 h-100 p-2">
                                        {proficient.map((el, i) => (
                                            <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                {el.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {expert.length !== 0 && (
                                <div className="col-md-6 my-4">
                                    <label className="form-label skill-levels">Expert</label>
                                    <div className="skill-board w-100 h-100 p-2">
                                        {expert.map((el, i) => (
                                            <span key={i} className="text-success m-1 rounded float-start d-flex align-items-center">
                                                {el.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Box>
            )}
        </div>
    );
}

export default React.memo(EnhancedTable);
