/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { createMessage } from "../../../../CheckContext";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ getData }) {
    const { t } = useTranslation();
    const [message, setMessage] = useContext(createMessage);
    const [levels, setSetLevels] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const validate = Yup.object({
        name: Yup.string().required(t("name is required")),
        level: Yup.string().required(t("Level is required")),
    });
    const emptyData = {
        name: "",
        level: "",
    };

    const onSubmit = async (values) => {
        setLoading(true);
        await axios
            .post(`${REACT_APP.API}/api/hrp/v1/employee/skill`, values, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Added successfully"));
                handleClose();
                getData();
                setMessage((prev) => !prev);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("An error occurred"));
            });
    };

    const getLevels = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/employee/skill/degree`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setSetLevels(res.data.data);
            })
            .catch((err) => {
                toast.error(t("An error occurred"));
            });
    };

    useEffect(() => {
        getLevels();
    }, []);

    return (
        <div className="addcompany">
            {loading && <div className="hrp-overlay"></div>}
            <Button onClick={handleOpen} variant="contained">
                {t("Add skill")}
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter skill info")}
                        </Typography>
                        <Formik
                            initialValues={emptyData}
                            validationSchema={validate}
                            onSubmit={async (values) => {
                                onSubmit(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth label={t("name")} name="name" component={TextField} autoComplete="name" type="text" />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth select name="level" component={TextField} label={t("Select level")}>
                                            {levels.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.id} key={index}>
                                                        {item.degree}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Field>
                                    </Grid>
                                    <div className="right_left">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error" className="mt-5">
                                            {t("Close")}
                                        </Button>
                                        <>
                                            {loading ? (
                                                <LoadingButton loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success" className="mt-5">
                                                    {t("Submit")}
                                                </LoadingButton>
                                            ) : (
                                                <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                    {t("Submit")}
                                                </Button>
                                            )}
                                        </>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
