import SkillsTable from "./SkillsTable";

const Skills = () => {
    return (
        <div className="container">
            <SkillsTable />
        </div>
    );
};

export default Skills;
