import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Notification({ setShow, show, messages }) {
    const { t } = useTranslation();
    const [showtrue, setShowTrue] = useState(false);
    useEffect(() => setShowTrue(show), [show]);

    const [sideStyle, setSideStyle] = useState({});
    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "320px", top: "64px" });
    };
    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        if (e.currentTarget === e.target) hide();
    };
    const hide = () => {
        setShowTrue(false);
        setTimeout(() => setShow(false), 300);
    };

    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-320px" }}>
                <div className={styles.title}>
                    <span className={styles.close} onClick={hide}>
                        &#x2715;
                    </span>
                </div>
                {messages.length === 0 && t("You have no notifications yet")}
                {messages.map((value, index) => {
                    return (
                        <div spacing={2} xs={12} key={index}>
                            <Link to={value.page} className={styles.card}>
                                <Card onClick={hide} className={styles.container}>
                                    <h6 className={styles.title}>{value.title}</h6> {value.message} <br /> {value.error}
                                </Card>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
