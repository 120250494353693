/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./components/Header";
import "./App.css";
import { ToastContainer } from "react-toastify";
import SpinnerTTS from "./components/spinner/Spinner";
import getQueryParam from "./hooks/getQueryParam";
import REACT_APP from "./environment";

function App() {
    const token = sessionStorage.getItem("token") || getQueryParam("user", window.location.search);
    const [wait, setWait] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            setWait(false);
        }, 1500);

        if (!token) {
            window.onbeforeunload = {};
            window.location.replace(`${REACT_APP.LOGIN}/login?url=${window.location.href}`);
        } else sessionStorage.setItem("token", token);
        navigate(`${window.location.pathname}`);
    }, []);

    return wait ? (
        <SpinnerTTS />
    ) : (
        <div data-testid="App">
            <ToastContainer />
            <Header />
        </div>
    );
}

export default App;
