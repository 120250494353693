/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect } from "react";
import randomcolor from "randomcolor";
import { Spin } from "antd";
import "./employment.css";
import axios from "axios";
import REACT_APP from "../../../environment";

const Loading = () => {
    return (
        <div id="body_spin">
            <Spin tip="Loading" size="large">
                <div className="content" />
            </Spin>
        </div>
    );
};

const Card1 = (props) => {
    const levelColor = randomcolor();
    const avatarImg = "https://www.shalukparamadrasah.com/images/employess.jpg";

    const getImage = (elem) => {
        fetch(`${REACT_APP.API}/file/${elem.image}`, {
            method: "GET",
            headers: {
                "client-id": REACT_APP.DOC_CLIENT_ID,
                secret: REACT_APP.DOC_SECRET,
                "Content-type": "image/*",
            },
        })
            .then((res) => res.blob())
            .then((blob) => {
                document.querySelector("#badge" + elem.id).src = window.URL.createObjectURL(blob);
            });
    };

    return (
        <ul className="d-flex p-0">
            {props.data?.map((item, index) => (
                <Fragment key={index}>
                    <li>
                        <div className="card1">
                            <div className="image">
                                {item.image ? getImage(item) : null}
                                <img id={"badge" + item.id} src={avatarImg} alt="Profile" style={{ borderColor: levelColor }} />
                            </div>
                            <div className="card1-body">
                                <h4 className="H4">{item.first_name + " " + item.last_name}</h4>
                            </div>
                            <div className="card1-footer" style={{ background: levelColor }}>
                                <p style={{ padding: "0px", margin: "0px" }}>
                                    {item.position?.length > 15 ? `${item.position?.slice(0, 14)}..` : item.position}
                                </p>
                            </div>
                        </div>
                        {item?.employees?.length ? <Card1 data={item.employees} /> : null}
                    </li>
                </Fragment>
            ))}
        </ul>
    );
};

const EmploymentChart = () => {
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);

    const getEmployees = async () => {
        setLoading(true);
        await axios(`${REACT_APP.API}/api/hrp/v1/organization/structure`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMsg(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        getEmployees();
    }, []);

    return (
        // <h1>Employment</h1>
        <div className="d-flex justify-content-center">
            <div className="org-tree overflow-auto">{loading ? <Loading /> : errorMsg ? "Error" : <Card1 data={data}></Card1>}</div>
        </div>
    );
};

export default EmploymentChart;
