/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "../MainNavigation.module.css";
import { CircularProgress } from "@mui/material";
import avatarImg from "../../images/avatar.jpg";
import { ProfileCrop } from "./ProfileCrop";
import { toast } from "react-toastify";
import axios from "axios";
import ChangePassword from "./ChangePassword";
import REACT_APP from "../../environment";
import { useTranslation } from "react-i18next";

const Profile = ({ getAvatar }) => {
    const { t } = useTranslation();
    const [imageLoading, setImageLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [change, setChange] = useState(false);
    const [data, setData] = useState([]);
    const [img, setImg] = useState(null);

    const getImage = async () => {
        setImageLoading(true);
        setImg(null);
        axios(`${REACT_APP.API}/api/hrp/v1/me/image`, {
            headers: { Authorization: sessionStorage.getItem("token") },
            responseType: "blob",
        })
            .then((res) => {
                setImg(URL.createObjectURL(res.data));
                setImageLoading(false);
            })
            .catch((err) => {
                setImageLoading(false);
                toast.error(t("An error occurred while getting image!"));
            });
    };

    const getData = () => {
        setLoading(true);
        setImg(null);
        axios(`${REACT_APP.AUTH}/api/hrp/v2/employee/info`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                setData(res.data.data);
                if (res.data.data.image) {
                    getImage();
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <section className="h-100">
            <ProfileCrop get={getImage} avatar={getAvatar} />
            <div className="container py-2 p-md-5 m-auto p-0 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    {loading ? (
                        <div className="text-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="col-12 m-0 p-0 col-lg-9 mb-md-4 mb-lg-0">
                            <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
                                <div className="row g-0">
                                    <div
                                        className={`${styles.gradientCustom} col-md-4 text-center text-white py-5`}
                                        style={{ borderTopLeftRadius: ".5rem", borderBottomLeftRadius: ".5rem" }}
                                    >
                                        {
                                            <div data-bs-toggle="modal" data-bs-target="#exampleModal" className={styles.profilePic}>
                                                <label className={styles.profileLabel} htmlFor="file">
                                                    <i className={`fa-solid fa-camera ${styles.profileSpan}`}></i>
                                                    <span className={styles.profileSpan}>{t("Change Image")}</span>
                                                </label>

                                                {imageLoading && (
                                                    <div className={styles.imgLoading}>
                                                        <CircularProgress />
                                                    </div>
                                                )}
                                                <img src={img ? img : avatarImg} alt="Avatar" className={`${styles.profileImg} img-fluid rounded-circle`} />
                                            </div>
                                        }
                                        <h5 className="m-auto mt-4 pt-2 mb-2">{data.first_name + " " + data.last_name}</h5>
                                        <p>{data.position}</p>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body p-4">
                                            <h6 className="fw-bold">{t("Information")}</h6>
                                            <hr className="mt-0 mb-4" />
                                            <div className="row pt-1">
                                                <div className="col-6 mb-3">
                                                    <h6>{t("Company")}</h6>
                                                    <p className="text-muted">{data.company_name}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <h6>{t("Branch")}</h6>
                                                    <p className="text-muted">{data.branch_name}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <h6>{t("Department")}</h6>
                                                    <p className="text-muted">{data.department_name}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <h6>{t("Email")}</h6>
                                                    <p className="text-muted">{data.email}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <h6>{t("Phone")}</h6>
                                                    <p className="text-muted">{data.cell_phone}</p>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <h6>{t("Roles")}</h6>
                                                    <p className="text-muted">{data.roles?.join(", ")}</p>
                                                </div>
                                            </div>
                                            <h6 className="fw-bold">{t("Settings")}</h6>
                                            <hr className="mt-0 mb-4" />
                                            <div className="">
                                                {change ? (
                                                    <ChangePassword setChange={setChange} />
                                                ) : (
                                                    <button className="btn btn-info mb-2" onClick={() => setChange(true)}>
                                                        {t("Change password")}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Profile;
