import React from "react";
import { useTranslation } from "react-i18next";

const Feedback = () => {
    const { t } = useTranslation();

    return (
        <div className="table21">
            <h4 className="mb-5 pb-5 position-absolute top-35 start-50 translate-middle">{t("Feedback")}</h4>
            <table className="mt-5 pt-5 ml-5 table container  center ">
                <thead>
                    <tr>
                        <th scope="col">Feedback</th>
                        <th scope="col">Feedback For</th>
                        <th scope="col">Request sent from</th>
                        <th scope="col">Request Date</th>
                        <th scope="col">Status </th>
                        <th scope="col">Feedback Date</th>
                        <th scope="col">Comments</th>
                        <th scope="col">Sample Text</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Users</th>
                        <td>Great</td>
                        <td>Javod</td>
                        <td>12.21.2021</td>
                        <td>Done</td>
                        <td>1.1.2022</td>
                        <td>Please review</td>
                        <td>checked</td>
                    </tr>
                    <tr>
                        <th scope="row">Users</th>
                        <td>Normal</td>
                        <td>Muhammadali</td>
                        <td>12.12.2019</td>
                        <td>Process</td>
                        <td>3.7.2020</td>
                        <td>Reviews</td>
                        <td>Done</td>
                    </tr>
                    <tr>
                        <th scope="row">Users</th>
                        <td>Awesome</td>
                        <td>Muhiddin</td>
                        <td>4.7.2021</td>
                        <td>Done</td>
                        <td>7.4.2021</td>
                        <td>View</td>
                        <td>Done</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Feedback;
