import React from "react";
import { useTranslation } from "react-i18next";

const Retirement = () => {
    const { t } = useTranslation();
    return (
        <div className="table21">
            <h4 className="mb-5 pb-5 position-absolute top-35 start-50 translate-middle">{t("Retirement plan")}</h4>
            <table className="mt-5 pt-5 ml-5 table container  center ">
                <thead>
                    <tr>
                        <th scope="col">Deduction</th>
                        <th scope="col">Plan Name</th>
                        <th scope="col">Begin Check Date</th>
                        <th scope="col">End Check Date</th>
                        <th scope="col">%/ Amount </th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Sample text</th>
                        <td>Sample text</td>
                        <td>Sample text</td>
                        <td>Sample text</td>
                        <td>Sample text</td>
                        <td>Sample text</td>
                    </tr>
                    <tr>
                        <th scope="row">Sample text</th>
                        <td>Sample text</td>
                        <td>Sample text</td>
                        <td>Sample text</td>
                        <td>Sample text</td>
                        <td>Sample text</td>
                    </tr>
                    <tr>
                        <th scope="row">Sample text</th>
                        <td>Sample text</td>
                        <td>Sample text</td>
                        <td>Sample text</td>
                        <td>Sample text</td>
                        <td>Sample text</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Retirement;
