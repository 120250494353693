import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    align-items: center;
`;

export const Item = styled.div`
    display: ${({ input }) => (input ? "flex" : "")};
    width: ${({ table }) => (table ? "70%" : "50%")};
    width: ${({ input }) => (input ? "63%" : "50%")};
    @media only screen and (max-width: 700px) {
        width: 90%;
    }
    width: 70%;
    margin-top: 50px;
`;

export const Input = styled.input`
    height: 40px;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
