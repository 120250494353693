/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, MenuItem } from "@mui/material";
import axios from "axios";
import { createMessage } from "../../../../CheckContext";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ getData }) {
    const { t } = useTranslation();
    const [message, setMessage] = useContext(createMessage);
    const [universitys, setUniversitys] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [diploma, setDiploma] = React.useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [empty, setEmpty] = React.useState({
        university_id: "",
        major: "",
        is_complete: false,
        years_of_edu: "",
        degree_type: "",
        diploma: "",
        start_date: "",
        end_date: "",
    });

    const validate = Yup.object({
        university_id: Yup.string().required(t("University is required")),
        major: Yup.string()
            .required(t("Major is required"))
            .matches(regex.major, { message: t("Enter valid major") }),
        is_complete: Yup.string().required(t("Is complete is required")),
        years_of_edu: Yup.string().required(t("Years of edu is required")),
        degree_type: Yup.string()
            .required(t("Degree type is required"))
            .matches(regex.degree_type, { message: t("Enter valid degree type") }),
        diploma: Yup.string(),
        start_date: Yup.string().required(t("Start date is required")),
        end_date: Yup.date().when("is_complete", (is_complete, schema) => {
            if (is_complete === true || is_complete === "true") {
                return schema.required(t("End date is required"));
            }
            return schema.optional();
        }),
    });

    const emptyData = {
        university_id: "",
        major: "",
        is_complete: false,
        years_of_edu: "",
        degree_type: "",
        diploma: "",
        start_date: "",
        end_date: "",
    };

    const onSubmit = async (values, setSubmitting) => {
        if (!values.diploma) delete values.diploma;
        if (!values.is_complete) {
            delete values?.diploma;
            delete values.end_date;
        }

        setLoading(true);
        await axios
            .post(`${REACT_APP.API}/api/hrp/v1/employee/education`, values, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Save successfully"));
                handleClose();
                getData();
                setMessage((prev) => !prev);
                setEmpty(emptyData);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("An error occurred"));
                setEmpty(emptyData);
            });
        setSubmitting(false);
    };

    const getRelections = async () => {
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/employee/educations`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setUniversitys(res.data.data);
            })
            .catch((err) => {
                toast.error(t("An error occurred"));
            });
    };

    useEffect(() => {
        getRelections();
    }, []);

    return (
        <div className="addcompany">
            {loading && <div className="hrp-overlay"></div>}
            <Button onClick={handleOpen} variant="contained">
                {t("Add education")}
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{ backdrop: { timeout: 500 } }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal    responsive_modal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Enter education info")}
                        </Typography>
                        <Formik initialValues={empty} validationSchema={validate} onSubmit={onSubmit}>
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <Grid item xs={12} className="marginBottom">
                                        <label className="mb-1">
                                            <Field type="checkbox" name="is_complete" />
                                            <span style={{ marginLeft: "5px", fontSize: "1.2rem" }}>{t("Is completed")}</span>
                                        </label>
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth select name="university_id" component={TextField} label={t("Select university")}>
                                            {universitys.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.id} key={index}>
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field fullWidth label={t("Major")} name="major" component={TextField} autoComplete="major" type="text" />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field
                                            fullWidth
                                            label={t("Years of education")}
                                            name="years_of_edu"
                                            component={TextField}
                                            autoComplete="years_of_edu"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Field
                                            fullWidth
                                            label={t("Degree type")}
                                            name="degree_type"
                                            component={TextField}
                                            autoComplete="degree_type"
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <label>{t("Start date")}</label>
                                        <Field fullWidth name="start_date" component={TextField} autoComplete="start_date" type="date" />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <label>{t("End date")}</label>
                                        <Field
                                            fullWidth
                                            name="end_date"
                                            autoComplete="end_date"
                                            component={TextField}
                                            type="date"
                                            disabled={!values.is_complete ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="marginBottom">
                                        <Button disabled={!values.is_complete ? true : false} variant="contained" className="relative">
                                            {t("Diploma file")}
                                            <input
                                                disabled={!values.is_complete ? true : false}
                                                type="file"
                                                onChange={(e) => setDiploma(e.target.files[0])}
                                                style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", opacity: "0" }}
                                            />
                                        </Button>
                                        <span style={{ marginLeft: "10px" }}>{diploma?.name}</span>
                                    </Grid>
                                    <div className="right_left">
                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                            {t("Close")}
                                        </Button>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                            {loading ? <p>{t("Submitting")}</p> : <p>{t("Submit")}</p>}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
