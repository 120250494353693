/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import RedeemIcon from "@mui/icons-material/Redeem";
import FlagIcon from "@mui/icons-material/Flag";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../environment";
import React from "react";
import Card from "./Card";
import axios from "axios";
import { useEffect } from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

const Welcome = () => {
    const { t } = useTranslation();
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const getData = () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/notification/main`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    useEffect(() => {
        getData();
    }, []);

    const Div = styled.div`
        width: 97%;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        flex-direction: column;
    `;
    const P = styled.h5`
        width: 100%;
        color: #aa00dd;
        font-size: 13pt;
        margin: 3px 0;
        padding: 2px 0;
        display: flex;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        background-color: #eaeaea;
    `;

    return (
        <div className="welcome w-100 h-100 px-lg-4">
            {/* <div className="w_title">
                <h1>{t("Welcome!")}</h1>
                <p>{t("Click one of the links below for where you want to go!")}</p>
            </div> */}
            {loading ? (
                <div className="center_center h-50">
                    <CircularProgress />
                </div>
            ) : (
                <div className="w_bottom row g-3">
                    {data?.calendar.length !== 0 ? (
                        <div className="col-xl-3 col-md-6">
                            <Card url="/hr/work/calendar" title={t("Calendar")} icon={<EventAvailableIcon fontSize="large" />}>
                                <Div className="mt-3">
                                    {data?.calendar.map((el) => (
                                        <P key={el.id}>{el.title}</P>
                                    ))}
                                </Div>
                            </Card>
                        </div>
                    ) : null}

                    {data?.timesheetDays.length !== 0 ? (
                        <div className="col-xl-3 col-md-6">
                            <a href={`${REACT_APP.LOGIN}/login?url=${REACT_APP.TIMESHEET_URL}`} target="_blank">
                                <Card title={t("Timesheet")} icon={<WorkHistoryIcon fontSize="large" />}>
                                    <Div className="mt-3">
                                        {data?.timesheetDays.map((el) => (
                                            <P key={el}>{el}</P>
                                        ))}
                                    </Div>
                                </Card>
                            </a>
                        </div>
                    ) : null}

                    {data?.timeoff?.id ? (
                        <div className="col-xl-3 col-md-6">
                            <Card url="/hr/time-off" title={t("Time off")} icon={<TimerOffIcon fontSize="large" />}>
                                <Div className="mt-3">
                                    <P>{data.timeoff.vacation_request_type}</P>
                                    <P>{data.timeoff.status}</P>
                                    <P>{data.timeoff.status_changed_date?.slice(0, 10)}</P>
                                </Div>
                            </Card>
                        </div>
                    ) : null}

                    {data?.recognition?.id ? (
                        <div className="col-xl-3 col-md-6">
                            <Card url="/hr/recognition/center" title={t("Recognition")} icon={<RedeemIcon fontSize="large" />}>
                                <Div className="mt-3">
                                    <P>{data.recognition.core_value_name}</P>
                                    <P>{data.recognition.comment}</P>
                                    <P>{data.recognition.date?.slice(0, 10)}</P>
                                </Div>
                            </Card>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default Welcome;
