/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import CachedIcon from "@mui/icons-material/Cached";
import axios from "axios";
import { toast } from "react-toastify";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import UpdateGoal from "./UpdateGoal";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

function AccountMenu({ id, status, getData }) {
    const { t } = useTranslation();
    const [waiting, setWaiting] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const onSubmit = async (id) => {
        setWaiting(true);
        await axios
            .patch(
                `${REACT_APP.API}/api/hrp/v1/goal/development/${id}/status/submit`,
                {},
                { headers: { "Content-Type": "application/json", Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setWaiting(false);
                toast.success("Goal submitted successfully");
                handleClose();
                getData();
            })
            .catch((err) => {
                setWaiting(false);
                toast.error(err.message);
            });
    };

    const onProgress = async (id) => {
        setWaiting(true);
        await axios
            .patch(
                `${REACT_APP.API}/api/hrp/v1/goal/development/${id}/status/progress`,
                {},
                { headers: { "Content-Type": "application/json", Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setWaiting(false);
                toast.success("Goal status changed in progress");
                handleClose();
                getData();
            })
            .catch((err) => {
                setWaiting(false);
                toast.error(err.message);
            });
    };

    const onComplete = async (id) => {
        setWaiting(true);
        await axios
            .patch(
                `${REACT_APP.API}/api/hrp/v1/goal/development/${id}/status/complete`,
                {},
                { headers: { "Content-Type": "application/json", Authorization: sessionStorage.getItem("token") } }
            )
            .then((res) => {
                setWaiting(false);
                toast.success("Goal completed successfully");
                handleClose();
                getData();
            })
            .catch((err) => {
                setWaiting(false);
                toast.error(err.message);
            });
    };

    const onDelete = async (id) => {
        setWaiting(true);
        await axios
            .delete(`${REACT_APP.API}/api/hrp/v1/goal/development/${id}`, {
                headers: { "Content-Type": "application/json", Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setWaiting(false);
                toast.success("Goal deleted successfully");
                handleClose();
                getData();
            })
            .catch((err) => {
                setWaiting(false);
                toast.error(err.message);
            });
    };

    return (
        <React.Fragment>
            {waiting && <div className="hrp-overlay"></div>}
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                >
                    <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={() => onSubmit(id)} disabled={status !== "new" && status !== "updated"}>
                    <TurnedInNotIcon /> {t("Submit")}
                </MenuItem>
                <MenuItem onClick={() => onProgress(id)} disabled={status !== "approved"}>
                    <CachedIcon /> {t("Progress")}
                </MenuItem>
                <MenuItem onClick={() => onComplete(id)} disabled={status !== "in_progress"}>
                    <DoneOutlineOutlinedIcon /> {t("Complete")}
                </MenuItem>
                <MenuItem disabled={status !== "new" && status !== "updated"}>
                    <UpdateGoal id={id} getDataa={getData} />
                </MenuItem>
                <MenuItem onClick={() => onDelete(id)} disabled={status !== "new" && status !== "updated"}>
                    <DeleteOutlineIcon /> {t("Delete")}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default React.memo(AccountMenu);
