import React, { useEffect, useState } from "react";
import "./Calendar.css";
import cn from "classnames";
import { dateLocalISO } from "../../hooks/dateFormatter";
import axios from "axios";
import REACT_APP from "../../environment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const WorkCalendar = () => {
    const { t } = useTranslation();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const [offs, setOffs] = useState({});
    const [holidays, setHolidays] = useState([]);

    const getData = async () => {
        await axios(`${REACT_APP.API}/api/hrp/v1/employee/calendar/work`, { headers: { Authorization: sessionStorage.getItem("token") } })
            .then((res) => {
                setOffs(res.data.data.offs);
                setHolidays(res.data.data.holidays);
            })
            .catch(() => toast.error("An error occurred"));
    };

    useEffect(() => {
        getData();
    }, []);

    const offDay = (date) => {
        if (offs[days[(date.getDay() + 6) % 7]] === false) return true;
        return Boolean(holidays.find((e) => e.date === dateLocalISO(date)));
    };

    const getDays = (month) => {
        const daysInTheMonth = [];
        const year = new Date().getFullYear();
        const firstDayOnMonth = new Date(year, month);

        const count = (firstDayOnMonth.getDay() + 6) % 7;
        for (let i = 0; i < count; i++) {
            const day = new Date(year, month, -i).getDate();
            daysInTheMonth.unshift(<div className={cn({ "day-off": offDay(new Date(year, month, -i)) }, "day-other", "day")}>{day}</div>);
        }

        const numberOfDays = new Date(year, month + 1, 0).getDate();
        const today = dateLocalISO(new Date());
        for (let i = 0; i < numberOfDays; i++) {
            const isToday = dateLocalISO(new Date(year, month, i + 1)) === today;
            daysInTheMonth.push(<div className={cn({ "day-off": offDay(new Date(year, month, i + 1)), today: isToday }, "day")}>{i + 1}</div>);
        }

        for (let i = 1, cd = new Date(year, month, numberOfDays); cd.getDay() !== 0; i++) {
            cd = new Date(year, month, numberOfDays + i);
            daysInTheMonth.push(<div className={cn({ "day-off": offDay(new Date(year, month, numberOfDays + i)) }, "day-other", "day")}>{i}</div>);
        }

        return daysInTheMonth;
    };

    return (
        <div className="wc-container">
            {months.map((month, index) => (
                <div className="wcc-month">
                    <div style={{ gridColumn: "span 7" }}>{t(month)}</div>
                    {days.map((day) => (
                        <div className={cn("wccm-week", { "day-off": offs[day] === false })}>{t(day.slice(0, 3))}</div>
                    ))}
                    <hr style={{ gridColumn: "span 7", margin: 0 }} />
                    {getDays(index)}
                </div>
            ))}
        </div>
    );
};

export default WorkCalendar;
