import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import Spinner from "react-spinner-material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ id, getContact }) {
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [loading, setLoading] = React.useState(false);
    const [wait, setWait] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getData = async () => {
        setWait(true);
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/employee/contact`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setWait(false);
                setData(res.data.data[0]);
            })
            .catch((err) => {
                toast.error(t("An error occurred"));
                setWait(false);
            });
    };

    const validate = Yup.object({
        home_phone: Yup.string()
            .required(t("Home phone is required"))
            .matches(regex.phone, { message: t("Enter valid phone") }),
        cell_phone: Yup.string()
            .required(t("Cell phone is required"))
            .matches(regex.phone, { message: t("Enter valid phone") }),
        email: Yup.string().email().required(t("email is required")),
    });

    var emptyData = data;

    const onUpdate = async (values) => {
        setLoading(true);
        await axios
            .patch(`${REACT_APP.API}/api/hrp/v1/employee/contact`, values, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Contact updated successfully"));
                handleClose();
                getContact();
                getData();
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("An error occurred"));
            });
    };
    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="addcompany">
            {loading && <div className="hrp-overlay"></div>}
            <Button onClick={handleOpen} title="Click">
                <Typography onClick={handleOpen}>{t("Edit contact")}</Typography>
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    {data === emptyData && (
                        <Box sx={style} className="addcompanymodal">
                            <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                                {t("Enter information")}
                            </Typography>
                            <Formik
                                initialValues={emptyData}
                                validationSchema={validate}
                                onSubmit={async (values) => {
                                    onUpdate(values);
                                    return new Promise((res) => setTimeout(res, 500));
                                }}
                            >
                                {({ values, errors }) => (
                                    <Form autoComplete="off" className="mt-4">
                                        {wait ? (
                                            <div
                                                style={{
                                                    marginTop: "100px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                                            </div>
                                        ) : (
                                            <>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} className="marginBottom">
                                                        <Field
                                                            fullWidth
                                                            name="home_phone"
                                                            label={t("Home Phone")}
                                                            autoComplete="home_phone"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className="marginBottom">
                                                        <Field
                                                            fullWidth
                                                            label={t("Cell Phone")}
                                                            name="cell_phone"
                                                            component={TextField}
                                                            autoComplete="cell_phone"
                                                            type="text"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className="marginBottom">
                                                        <Field
                                                            fullWidth
                                                            label={t("Email")}
                                                            name="email"
                                                            component={TextField}
                                                            autoComplete="email"
                                                            type="text"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <div className="right_left res">
                                                    <div>
                                                        <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error" className="mt-5">
                                                            {t("Close")}
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        {loading ? (
                                                            <LoadingButton
                                                                type="submit"
                                                                loading
                                                                variant="contained"
                                                                sx={{ mt: 3, mb: 2 }}
                                                                color="success"
                                                                className="mt-5"
                                                            >
                                                                {t("Update")}
                                                            </LoadingButton>
                                                        ) : (
                                                            <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                                {t("Update")}
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    )}
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
