import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import Spinner from "react-spinner-material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";
import regex from "tts-hrp-val-lib";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function TransitionsModal({ id, getDataa }) {
    const { t } = useTranslation();
    const [data, setData] = useState();
    const [loading, setLoading] = React.useState(false);
    const [wait, setWait] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        getData();
    };
    const handleClose = () => setOpen(false);

    const getData = async () => {
        setWait(true);
        await axios
            .get(`${REACT_APP.API}/api/hrp/v1/goal/development/${id}`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setWait(false);
                setData(res.data.data);
            })
            .catch((err) => {
                toast.error(err.message);
                setWait(false);
                console.log(err);
            });
    };

    const validateCompany = Yup.object({
        goal_name: Yup.string()
            .required(t("Name is required"))
            .matches(regex.goal_name, { message: t("Enter valid goal name") }),
        short_description: Yup.string()
            .required(t("Email is required"))
            .matches(regex.description, { message: t("Enter valid short description") }),
    });

    const emptyData = data;

    const onUpdate = async (values) => {
        const data = { goal_name: values.goal_name, short_description: values.short_description };
        setLoading(true);
        await axios
            .put(`${REACT_APP.API}/api/hrp/v1/goal/development/${id}`, data, {
                headers: { "Content-Type": "application/json", Authorization: sessionStorage.getItem(`token`) },
            })
            .then((res) => {
                setLoading(false);
                toast.success(t("Goal updated successfully"));
                handleClose();
                getDataa();
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                toast.error(err.message);
            });
    };

    return (
        <div className="addcompany">
            {loading && <div className="hrp-overlay"></div>}
            <Typography onClick={handleOpen}>
                <ModeEditOutlineOutlinedIcon /> {t("Update")}
            </Typography>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className="addcompanymodal">
                        <Typography id="transition-modal-title" variant="h6" component="h2" className="marginBottom">
                            {t("Update goal")}
                        </Typography>
                        <Formik
                            initialValues={emptyData}
                            validationSchema={validateCompany}
                            onSubmit={async (values) => {
                                onUpdate(values);
                                return new Promise((res) => setTimeout(res, 500));
                            }}
                        >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    {wait ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                                        </div>
                                    ) : (
                                        <>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field
                                                        fullWidth
                                                        label={t("Goal name")}
                                                        name="goal_name"
                                                        component={TextField}
                                                        autoComplete="password"
                                                        type="text"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className="marginBottom">
                                                <Field
                                                    fullWidth
                                                    name="short_description"
                                                    label={t("Short description")}
                                                    autoComplete="email"
                                                    component={TextField}
                                                />
                                            </Grid>
                                            <div className="left_right">
                                                <Button onClick={handleClose} variant="contained" sx={{ mt: 3 }} color="error">
                                                    {t("Close")}
                                                </Button>
                                                {loading ? (
                                                    <LoadingButton loading variant="contained" sx={{ mt: 3 }} color="success">
                                                        {t("Update")}
                                                    </LoadingButton>
                                                ) : (
                                                    <Button type="submit" variant="contained" sx={{ mt: 3 }} color="success">
                                                        {t("Update")}
                                                    </Button>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default React.memo(TransitionsModal);
