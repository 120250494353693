import React from "react";
import { useTranslation } from "react-i18next";

const DEDUCTIONS = [
    {
        id: "1",
        tax: "some tax",
        filingStatus: "some status",
        dependents: "family",
        otherIncome: "second work",
        deductions: "some deductions",
        type: "some type",
        amount: "some amount",
        percentage: "2%",
    },
    {
        id: "2",
        tax: "some tax",
        filingStatus: "some status",
        dependents: "family",
        otherIncome: "second work",
        deductions: "some deductions",
        type: "some type",
        amount: "some amount",
        percentage: "2%",
    },
    {
        id: "3",
        tax: "some tax",
        filingStatus: "some status",
        dependents: "family",
        otherIncome: "second work",
        deductions: "some deductions",
        type: "some type",
        amount: "some amount",
        percentage: "2%",
    },
];

const Deductions = () => {
    const { t } = useTranslation();

    return (
        <div className="container mt-3 cntnr2">
            <h2 className="h2">Deductions</h2>
            <div className="table-responsive">
                <table data-testid="table" className="table table-bordered">
                    <thead data-testid="thead">
                        <tr>
                            <th>{t("Tax")}</th>
                            <th>{t("Filing Status")}</th>
                            <th>{t("Dependents")}</th>
                            <th>{t("Other income")}</th>
                            <th>{t("Deductions")}</th>
                            <th>{t("Type")}</th>
                            <th>{t("Amount")}</th>
                            <th>{t("Percentage")}</th>
                        </tr>
                    </thead>
                    <tbody data-testid="tbody">
                        {DEDUCTIONS.map((DEDUCTION) => (
                            <tr key={DEDUCTION.id}>
                                <td>{DEDUCTION.tax}</td>
                                <td>{DEDUCTION.filingStatus}</td>
                                <td>{DEDUCTION.dependents}</td>
                                <td>{DEDUCTION.otherIncome}</td>
                                <td>{DEDUCTION.deductions}</td>
                                <td>{DEDUCTION.type}</td>
                                <td>{DEDUCTION.amount}</td>
                                <td>{DEDUCTION.percentage}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Deductions;
