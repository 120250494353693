import { CircularProgress } from "@mui/material";
import React, { useRef, useState } from "react";
import Avatar from "react-avatar-edit";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../environment";

export const ProfileCrop = ({ get, avatar }) => {
    const { t } = useTranslation();
    const [avatarWidth, setAvatarWidth] = useState(467);
    const [loading, setLoading] = useState(false);
    const [pview, setPview] = useState(false);
    const closeRef = useRef(null);

    const onClose = () => setPview(null);
    const onCrop = (preview) => setPview(preview);

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 221680) {
            toast.error("File is too big!");
            elem.target.value = "";
        }
    };

    const saveCropImage = async () => {
        setLoading(true);
        const blob = await fetch(pview).then((res) => res.blob());
        const body = new FormData();
        body.append("image", blob);

        axios
            .put(`${REACT_APP.API}/api/hrp/v1/me/image`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                get();
                avatar();
                setPview(false);
                setLoading(false);
                setTimeout(() => {
                    closeRef.current.click();
                });
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    useEffect(() => {
        if (window.screen.width < 660) setAvatarWidth("100%");
    }, []);

    return (
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            {loading && <div className="hrp-overlay"></div>}
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{t("Modal title")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <Avatar width={avatarWidth} height={275} onCrop={onCrop} onClose={onClose} onBeforeFileLoad={onBeforeFileLoad} src={""} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button disabled={loading} type="button" ref={closeRef} className="btn btn-secondary" data-bs-dismiss="modal">
                            {t("Close")}
                        </button>
                        <button disabled={loading || !pview} onClick={saveCropImage} type="button" className="btn btn-primary">
                            {loading && <CircularProgress style={{ color: "white", margin: "0 3px -2px 0" }} size="0.9rem" />} {t("Save")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
