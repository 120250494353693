import React, { useEffect } from "react";
import "./Dashboard.css";
import PieChart from "./PieChart";
import BarCharts from "./BarCharts";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

const Dashboard = () => {
    const { t } = useTranslation();
    const [coreStatus, setCoreStatus] = React.useState([]);
    const [coreCount, setCoreCount] = React.useState([]);
    const [devStatus, setDevStatus] = React.useState([]);
    const [devCount, setDevCount] = React.useState([]);
    const [coreId, setCoreId] = React.useState([]);
    const [coreIdValue, setCoreIdValue] = React.useState([]);
    const [Loading, setLoading] = React.useState(true);

    const getData = async () => {
        setLoading(true);
        await axios(`${REACT_APP.API}/api/hrp/v1/goal/statuses/performance`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                res.data.data.forEach((el) => {
                    setCoreStatus((prev) => [...prev, el.status]);
                    setCoreCount((prev) => [...prev, el.count * 1]);
                });
            })
            .catch((err) => {
                toast.error(err.message);
            });

        await axios(`${REACT_APP.API}/api/hrp/v1/goal/statuses/development`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) => {
                res.data.data.forEach((el) => {
                    setDevStatus((prev) => [...prev, el.status]);
                    setDevCount((prev) => [...prev, el.count * 1]);
                });
            })
            .catch((err) => {
                console.log(err);
            });

        await axios(`${REACT_APP.API}/api/hrp/v1/recognition/core`, {
            headers: { Authorization: sessionStorage.getItem("token") },
        })
            .then((res) =>
                res.data.data.forEach((el) => {
                    axios(`${REACT_APP.API}/api/hrp/v1/recognize?core_id=${el.id}`, {
                        headers: { Authorization: sessionStorage.getItem("token") },
                    })
                        .then((res) => {
                            setCoreId((prev) => [el.id, ...prev]);
                            setCoreIdValue((prev) => [res.data.data.length, ...prev]);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
            )
            .catch((err) => {
                console.log(err);
            });

        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="Dashboard">
            <div className="row dshbrd">
                <div className="card col-12 crds">
                    <div className="card-header">{t("My Goals")}</div>
                    {Loading ? (
                        <div className="text-center my-5 py-5">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="card-body">
                            <div className="row g-2">
                                <div className="col-xl-4 text-center">
                                    <h4 className="mb-1 mb-sm-4">{t("Core Value Goals")}</h4>
                                    <PieChart st={coreStatus} count={coreCount} />
                                </div>
                                <div className="col-xl-4 text-center">
                                    <h4 className="mb-1 mb-sm-4">{t("Development Goals")}</h4>
                                    <PieChart st={devStatus} count={devCount} />
                                </div>
                                <div className="col-xl-4 text-center">
                                    <h4 className="mb-1 mb-sm-4">{t("Recognition")}</h4>
                                    <BarCharts id={coreId} count={coreIdValue} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="card col-md-12 crds">
                    <div className="card-header">{t("My Reviews")}</div>
                    <div className="card-body">
                        <select className="form-select w-25 my-2">
                            <option>2021</option>
                            <option>2022</option>
                            <option>2023</option>
                        </select>
                        <div className="row">
                            <div className="col-6">
                                <p>{t("Active")}</p>
                                <p>5</p>
                            </div>
                            <div className="col-6">
                                <p>{t("Complete")}</p>
                                <p>6</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
