/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import * as React from "react";
import { Box } from "@mui/material";
import axios from "axios";
import Spinner from "react-spinner-material";
import { ToastContainer, toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";
import "./recognition.css";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import InputLabel from "@mui/material/InputLabel";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Textarea from "@mui/joy/Textarea";
import badge4 from "./badges/wait.gif";
import validation from "./validator";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import REACT_APP from "../../../../environment";

function RecognitionScreen() {
    const { t } = useTranslation();
    const [employees, setEmployees] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [waiting, setWaiting] = React.useState(false);
    const [selectBadge, setSelectBadge] = React.useState(null);
    const [selectEmployees, setSelectEmployees] = React.useState([]);
    const [notify, setNotify] = React.useState(true);
    const [isPublic, setIsPublic] = useState("public");
    const [coreValues, setCoreValues] = useState([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        getAllVacations();
        getAllCoreValues();
    }, []);

    const getAllVacations = () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/employees/recognition`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                res.data.data.forEach((e) => {
                    e.name = toTitleCase(e.first_name + " " + e.last_name);
                });
                setEmployees(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(t("Something went wrong"));
            });
    };

    const getAllCoreValues = () => {
        axios
            .get(`${REACT_APP.API}/api/hrp/v1/recognition/core`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setCoreValues(res.data.data);
                for (let i = 0; i < res.data.data.length; i++) {
                    fetch(`${REACT_APP.API}/api/hrp/v1/recognition/core/image/${res.data.data[i].image}`, {
                        method: "GET",
                        headers: { Authorization: sessionStorage.getItem("token") },
                    })
                        .then((res) => res.blob())
                        .then((blob) => {
                            if (/image/.test(blob.type)) {
                                document.querySelector("#badge" + res.data.data[i].id).src = window.URL.createObjectURL(blob);
                            }
                        });
                }
            })
            .catch((err) => {
                toast.error(t("Something went wrong"));
            });
    };

    function submitHandler(event) {
        event.preventDefault();

        const body = {
            to_employee_id: selectEmployees,
            comment: event.target.comment?.value,
            core_value_id: selectBadge,
            notify_manager: notify,
            is_public: isPublic === "public",
        };

        if (validation.validate(body).error) {
            return toast.error(validation.validate(body).error.message);
        }

        setWaiting(true);
        axios
            .post(`${REACT_APP.API}/api/hrp/v1/recognize`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setWaiting(false);
                navigate("/hr/recognition/center");
                toast.success("Successfully recognized");
            })
            .catch((err) => {
                setWaiting(false);
                toast.error(t("Something went wrong"));
            });
    }

    return (
        <div>
            {waiting && <div className="hrp-overlay"></div>}
            <Box m={5}>
                {loading && (
                    <div className="spinner21">
                        <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                    </div>
                )}
                {!loading && (
                    <>
                        <form onSubmit={submitHandler}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel className="recg-label">{t("Select employees")}</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Multiselect
                                        placeholder={t("Select")}
                                        defaultValue={""}
                                        options={employees}
                                        displayValue="name"
                                        value="id"
                                        closeIcon="circle"
                                        onSelect={(a) => {
                                            let ids = [];
                                            a.map((e) => ids.push(e.id));
                                            setSelectEmployees(ids);
                                        }}
                                        onRemove={(a) => {
                                            let ids = [];
                                            a.map((e) => ids.push(e.id));
                                            setSelectEmployees(ids);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel className="recg-label">{t("Comment")}</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <Textarea color="neutral" minRows={3} maxRows={5} name="comment" />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel className="recg-label">{t("Select a badge")}</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <div className="badges">
                                        {coreValues.map((e) => (
                                            <Tooltip title={e.name} key={e.id}>
                                                <img
                                                    src={badge4}
                                                    id={"badge" + e.id}
                                                    alt="badge"
                                                    className={selectBadge === e.id ? "active" : ""}
                                                    onClick={() => setSelectBadge(e.id)}
                                                />
                                            </Tooltip>
                                        ))}
                                    </div>
                                    <div className="badge-name">{coreValues[coreValues.findIndex((x) => x.id === selectBadge)]?.name}</div>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <InputLabel className="recg-label">{t("Notification")}</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <FormControlLabel control={<Checkbox defaultChecked onChange={() => setNotify(!notify)} />} label={t("Notify manager")} />
                                </Grid>
                                <Grid item xs={12} sm={3}></Grid>
                                <Grid item xs={12} sm={9}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={isPublic}
                                        onChange={(e) => {
                                            setIsPublic(e.target.value);
                                        }}
                                    >
                                        <FormControlLabel value="public" control={<Radio />} label={t("Public")} />
                                        <FormControlLabel value="private" control={<Radio />} label={t("Private")} />
                                    </RadioGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} />
                                <Grid item xs={12} sm={6}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "baseline",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="error"
                                            style={{
                                                marginRight: "2rem",
                                            }}
                                            onClick={() => {
                                                navigate("/hr/recognition/center");
                                            }}
                                        >
                                            {t("Cancel")}
                                        </Button>
                                        <Button variant="contained" type="submit">
                                            {t("Recognize")}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </>
                )}
            </Box>
        </div>
    );
}

export default React.memo(RecognitionScreen);

function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
