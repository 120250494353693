import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

const Card = (props) => {
    const { title, icon, url, children } = props;
    const navigate = useNavigate();

    const Container = styled.div`
        width: 100%;
        height: 380px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
    `;
    const Card = styled.div`
        width: 280px;
        height: 380px;
        display: flex;
        padding: 25px;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        flex-direction: column;
        background-color: #f1f1f1;
    `;
    const Span = styled.span`
        width: 77px;
        height: 77px;
        display: flex;
        color: #4a7c6b;
        border-radius: 50%;
        background: #dfdfdf;
        align-items: center;
        justify-content: center;
    `;
    const H3 = styled.h3`
        color: #4a7c6b;
        font-size: 20pt;
        margin-top: 20px;
    `;

    return (
        <Container onClick={() => navigate(url)}>
            <Card>
                <Span>{icon}</Span>
                {children}
                <H3>{title}</H3>
            </Card>
        </Container>
    );
};

export default Card;
